//src/utils/constants.tsx

export const AdminMenuItems = [
  {
    name: "Home",
    url: "/admin/dashboard/home",
    icon: "/img/icons/menuHomeNotSelected.svg",
    iconSelected: "/img/icons/menuHomeSelected.svg",
  },
  {
    name: "Calendar",
    url: "/admin/dashboard/calendar",
    icon: "/img/icons/menuCalendarNotSelected.svg",
    iconSelected: "/img/icons/menuCalendarSelected.svg",
  },
  {
    name: "Schedule",
    url: "/admin/dashboard/schedule",
    icon: "/img/icons/menuScheduleNotSelected.svg",
    iconSelected: "/img/icons/menuScheduleSelected.svg",
  },
  {
    name: "Overview",
    url: "/admin/dashboard/overview",
    icon: "/img/icons/menuOverviewNotSelected.svg",
    iconSelected: "/img/icons/menuOverviewSelected.svg",
  },
  {
    name: "Chat",
    url: "/admin/dashboard/chat",
    icon: "/img/icons/menuChatNotSelected.svg",
    iconSelected: "/img/icons/menuChatSelected.svg",
  },
  {
    name: "Clients",
    url: "/admin/dashboard/clients",
    icon: "/img/icons/menuClientNotSelected.svg",
    iconSelected: "/img/icons/menuClientSelected.svg",
  },
  {
    name: "Reviews",
    url: "/admin/dashboard/reviews",
    icon: "/img/icons/menuReviewNotSelected.svg",
    iconSelected: "/img/icons/menuReviewSelected.svg",
  },
  {
    name: "Articles",
    url: "/admin/dashboard/articles",
    icon: "/img/icons/menuArticleNotSelected.svg",
    iconSelected: "/img/icons/menuArticleSelected.svg",
  },
  {
    name: "Transaction",
    url: "/admin/dashboard/transaction",
    icon: "/img/icons/menuTransactionNotSelected.svg",
    iconSelected: "/img/icons/menuTransactionSelected.svg",
  },
  {
    name: "Pricing Tables",
    url: "/admin/dashboard/membership-packages",
    icon: "/img/icons/menuPriceNotSelected.svg",
    iconSelected: "/img/icons/menuPriceSelected.svg",
  },
  {
    name: "FAQ",
    url: "/admin/dashboard/faq",
    icon: "/img/icons/menuFaqNotSelected.svg",
    iconSelected: "/img/icons/menuFaqSelected.svg",
  },
  {
    name: "Profession List",
    url: "/admin/dashboard/professions",
    icon: "/img/icons/menuProfessionNotSelected.svg",
    iconSelected: "/img/icons/menuProfessionSelected.svg",
  },
  {
    name: "Coupons",
    url: "/admin/dashboard/discount-coupons",
    icon: "/img/icons/menuCouponNotSelected.svg",
    iconSelected: "/img/icons/menuCouponSelected.svg",
  },
  {
    name: "Home Sliders",
    url: "/admin/dashboard/home-sliders",
    icon: "",
    iconSelected: "",
  },
  {
    name: "Settings",
    url: "/admin/dashboard/setting",
    icon: "/img/icons/menuHomeNotSelected.svg",
    iconSelected: "/img/icons/menuHomeSelected.svg",
  },
];

export const MembershipTopColors = [
  "#0069FF",
  "#1AE364",
  "#FFD507",
  "#FF6155",
  "#8B5CF6",
  "#38BDF8",
];

export const PublicSubscriptionOfferItems = [
  {
    icon: "/img/icons/blueLock.svg",
    title: "Trusted and \nSecure",
    desc: "Your personal data is paramount to us. We employ state-of-the-art encryption methods to ensure your information remains confidential and protected.",
  },
  {
    icon: "/img/icons/blueWallet.svg",
    title: "Affordable \nPricing",
    desc: "Quality doesn't always mean expensive. At Minimum Stress, we offer competitive rates ensuring you get value for your money.",
  },
  {
    icon: "/img/icons/bluePackage.svg",
    title: "Flexible Membership \nOptions",
    desc: "With tiered membership programs, you can choose a plan that best fits your needs, all while enjoying attractive discounts on sessions.",
  },
  {
    icon: "/img/icons/blueWorld.svg",
    title: "Service in Your Native \nLanguage",
    desc: "Communication is key. That's why we offer services in various languages, ensuring you can converse in the language you're most comfortable with.",
  },
  {
    icon: "/img/icons/blueChurch.svg",
    title: "All-In-One Holistic \nHealth Hub",
    desc: "From yoga and meditation instructors to dietitians and life coaches, find all holistic health professionals in one platform. This is a pioneering effort in bringing together various wellness experts under one roof.",
  },
  {
    icon: "/img/icons/blueTimer.svg",
    title: "24/7 \nAccess",
    desc: "Whenever you need, wherever you are. Our platform is accessible round the clock, ensuring you get assistance when you need it the most.",
  },
  {
    icon: "/img/icons/bluePerson.svg",
    title: "User-Friendly \nInterface",
    desc: "Our platform is designed with you in mind. Navigate with ease and find the resources or experts you're looking for in just a few clicks.",
  },
  {
    icon: "/img/icons/blueStudent.svg",
    title: "Expertise \nQuality",
    desc: "Our advisors are not just professionals; they are experts in their respective fields. Learn more about their training, certifications, and experience directly on our platform",
  },
];

export enum AdminHomeStatCardArrowCondition {
  up = "up",
  down = "down",
  none = "none",
}

export enum AdminHomeStatCardSubValueColor {
  positive = "pozitive",
  negative = "negative",
}

export enum Roles {
  patient = "patient",
  consultant = "consultant",
  admin = "admin",
  superAdmin = "superAdmin",
}

export const ConsultantMenuItems = [
  {
    name: "Home",
    url: "/consultant/dashboard/home",
    icon: "/img/icons/menuHomeNotSelected.svg",
    iconSelected: "/img/icons/menuHomeSelected.svg",
  },
  {
    name: "Calendar",
    url: "/consultant/dashboard/calendar",
    icon: "/img/icons/menuCalendarNotSelected.svg",
    iconSelected: "/img/icons/menuCalendarSelected.svg",
  },
  {
    name: "Payments",
    url: "/consultant/dashboard/overview",
    icon: "/img/icons/menuOverviewNotSelected.svg",
    iconSelected: "/img/icons/menuOverviewSelected.svg",
  },
  {
    name: "Settings",
    url: "/admin/dashboard/setting",
    icon: "/img/icons/menuHomeNotSelected.svg",
    iconSelected: "/img/icons/menuHomeSelected.svg",
  },
];
