import script from "./ConsultantDetails.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConsultantDetails.vue?vue&type=script&setup=true&lang=ts"

import "./ConsultantDetails.vue?vue&type=style&index=0&id=950519fc&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QSeparator,QCard,QCardSection,QExpansionItem,QChip});
