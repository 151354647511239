<!-- // src/components/SubscriptionCard.vue -->
<template>
  <div class="col-md-6 col-lg-4 col-sm-12">
    <q-card class="q-pb-lg" :style="{
      'border-radius': '8px',
      'border-right': '1px solid #E4E4E7',
      'border-bottom': '1px solid #E4E4E7',
      'border-left': '1px solid  #E4E4E7',
      'border-top': `2px solid ${borderColor}`,
      'box-shadow': 'none',
    }">
      <div v-if="isPopular" style="display: flex; align-items: center; justify-content: center; margin-top: -16px;">
        <div
          :style="{ 'padding': '6px 12px', 'background-color': borderColor, 'width': 'fit-content', 'border-radius': '20px' }">
          <span style="font-family: 'Red Hat Display';
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 125%;
                        text-align: start;
                        color:#FFFFFF">
            Most Popular
          </span>
        </div>
      </div>
      <div v-if="!isPopular" style="margin-top: 17.2px;"></div>

      <q-card-section class="text-left">
        <div class="text-h6 text-weight-bold" style="color: #18181b">
          {{ title }}
        </div>
        <div class="text-subtitle2" style="font-weight: 400; font-size: 14px;">{{ subtitle }}</div>
      </q-card-section>

      <q-card-section class="text-left" style="display: flex; align-items: center;">
        <span class="text-h6 text-weight-bold" style="color: #18181b">${{
          price
        }}</span>
        <small>/ month</small>
      </q-card-section>

      <q-card-section class="text-left q-mb-xl">
        <div class="text-weight-bold" style="color: #18181b; font-weight: 700;">What you get:</div>
        <q-list v-for="item in list">
          <q-item class="q-pl-none q-pb-none">
            <q-item-section avatar class="q-pr-none" style="max-width: 30px">
              <img src="/img/icons/checkmark-blue.svg" alt="checkmark-blue" />
            </q-item-section>
            <q-item-section>{{ item }}</q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <q-card-actions>
        <q-btn v-if="!isInEditMode" style="
            border-radius: 16px !important;
            background-color: #0069ff !important;
            color:#FFFFFF;
            font-size: 14px;
            font-weight: 700;
          " label="Get Started" size="md" class="full-width text-capitalize" />
        <q-btn v-if="isInEditMode" style="
            border-radius: 16px !important;
            background-color: #0069ff !important;
            color:#FFFFFF;
            font-size: 14px;
            padding: 10px !important;
            font-weight: 700;
          " label="Edit Package" icon="img:/img/icons/penWithBottomLineWhite.svg" size="md"
          class="full-width text-capitalize" />
      </q-card-actions>
    </q-card>
  </div>
</template>
<script setup lang="ts">
defineProps<{
  title: string;
  subtitle: string;
  price: string;
  borderColor: string;
  isInEditMode: boolean;
  isPopular: boolean
}>();

const list = [
  "Get 15% off all bookings",
  "Change consultants anytime",
  "Join 1 group class for free each month",
];
</script>