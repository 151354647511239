// src/services/profession.service.ts

import axiosInstance from "@/utils/axios";
import { defineStore } from "pinia";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { reactive } from "vue";

interface IQuoteCreateDto {
    quote: string
    author: string
}

interface IIdBody {
    id: string;
}

interface IQuoteEditDto extends IQuoteCreateDto{
    id:string
}

interface IQuoteListingItem {
    _id: string
    quote: string
    author: string
}

interface IState {
    quotes: IQuoteListingItem[];
    quote: IQuoteListingItem;
    errorMessage: null | string;
}

export const useHomePageSlideQuote = defineStore("homeslideQuote", () => {
    const $q = useQuasar();

    const state = reactive<IState>({
        quotes: [] as IQuoteListingItem[],
        errorMessage: null,
        quote:{} as IQuoteListingItem
    });

    const getSliderQuotes = async () => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            const quotesResponse = await axiosInstance.get("/home-slide-quote");
            state.quotes = quotesResponse.data
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while quotes list.";
            $q.loading.hide();
        }
    }

    const createSliderQuote = async (dto: IQuoteCreateDto) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            await axiosInstance.post("/home-slide-quote/create", dto);
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while adding new quote.";
            $q.loading.hide();
        }
    }

    const deleteSliderQuote = async ({ id }: IIdBody) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            await axiosInstance.delete(`/home-slide-quote/${id}`);
            state.quotes = state.quotes.filter((e) => e._id != id)
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while deleting quote.";
            $q.loading.hide();
        }
    }

    const getSliderQuote = async ({ id }: IIdBody) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            const quoteResponse = await axiosInstance.get(`/home-slide-quote/${id}`)
            state.quote = quoteResponse.data
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while editing quote.";
            $q.loading.hide();
        }
    }

    const updateSliderQuote = async ({id, author, quote}:IQuoteEditDto) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            await axiosInstance.put(`home-slide-quote/${id}`,{quote, author})
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while editing quote.";
            $q.loading.hide();
        }
    }

    const removeError = () => {
        state.errorMessage = null
    }

    return {
        state,
        getSliderQuotes,
        getSliderQuote,
        createSliderQuote,
        deleteSliderQuote,
        updateSliderQuote,
        removeError
    }
})