import script from "./QPhoneInput.vue?vue&type=script&lang=ts&setup=true"
export * from "./QPhoneInput.vue?vue&type=script&lang=ts&setup=true"

import "./QPhoneInput.vue?vue&type=style&index=0&id=18480675&lang=css"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QField});
