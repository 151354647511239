<!-- src/components/AppointmentModal.vue -->
<template>
<q-dialog :model-value="isOpen" @update:model-value="$emit('update:isOpen', $event)" persistent>
      <q-card class="appointment-modal">
        <q-card-section class="row items-center q-pb-none header">
          <div class="text-h6">Make an Appointment</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup class="close-btn" />
        </q-card-section>
  
        <q-card-section class="consultant-info">
          <div class="row items-center">
            <q-avatar size="80px">
              <img :src="consultant.avatar" />
            </q-avatar>
            <div class="q-ml-md consultant-details">
              <div class="consultant-name">{{ consultant.name }}</div>
              <div class="consultant-profession">{{ consultant.profession }}</div>
              <div class="row items-center">
                <span class="consultant-rating">{{ consultant.rating }}</span>
                <q-icon name="star" color="blue" size="18px" class="q-ml-xs" />
              </div>
            </div>
          </div>
          <div class="bulk-info q-mt-md">
            For bulk session purchases, you can only select the date and time for the first session.
          </div>
        </q-card-section>
  
        <q-card-section v-if="currentStep === 1" class="general-options-wrapper">
          <div class="session-options">
            <div class="row q-col-gutter-md">
              <div class="col-12 col-sm-6">
                <q-card
                  :class="{ 'selected-session': selectedSession === 'free' }"
                  clickable
                  @click="selectSession('free')"
                  class="session-card"
                >
                  <q-card-section>
                    <div class="text-weight-bold">Free</div>
                    <div class="session-duration">15 Minutes</div>
                    <div class="session-price">$0</div>
                  </q-card-section>
                  <q-icon v-if="selectedSession === 'free'" name="check_circle" class="selected-icon" />
                </q-card>
              </div>
              <div class="col-12 col-sm-6">
                <q-card
                  :class="{ 'selected-session': selectedSession === 'single' }"
                  clickable
                  @click="selectSession('single')"
                  class="session-card"
                >
                  <q-card-section>
                    <div class="text-weight-bold">Single Session</div>
                    <div class="session-duration">45 Minutes</div>
                    <div class="session-price">$19.90 <span class="text-caption">/ Session</span></div>
                  </q-card-section>
                  <q-icon v-if="selectedSession === 'single'" name="check_circle" class="selected-icon" />
                </q-card>
              </div>
            </div>
  
            <div class="row q-col-gutter-sm q-mt-md">
              <div v-for="session in multiSessions" :key="session.count" class="col-6 col-sm-3">
                <q-card
                  :class="{ 'selected-session': selectedSession.toString() === session.count.toString() }"
                  clickable
                  @click="selectSession(session.count)"
                  class="session-card"
                >
                  <q-card-section>
                    <div class="text-weight-bold">{{ session.count }} Session</div>
                    <div class="session-duration">45 Minutes</div>
                    <div>
                      <span class="original-price">${{ session.originalPrice }}</span>
                      <span class="discounted-price">${{ session.discountedPrice }}</span>
                      <span class="text-caption">/ Session</span>
                    </div>
                  </q-card-section>
                  <q-icon v-if="selectedSession.toString() === session.count.toString()" name="check_circle" class="selected-icon" />
                </q-card>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-section v-if="currentStep === 2" class="general-options-wrapper">
            <div class="date-time-selection">
        <div class="row q-col-gutter-md">
          <div class="col-12 col-md-7">
            <q-date v-model="selectedDate" :options="dateOptions" minimal flat class="full-width"style="border: 1px solid #E2E8F0;border-radius: 8px;" />
          </div>
          <div class="col-12 col-md-5">
            <div class="text-h6 q-mb-md">Available Times</div>
            <div class="row q-col-gutter-sm">
              <div v-for="time in availableTimes" :key="time" class="col-4 col-sm-6">
                <q-btn 
                style="
                    background: #FFFFFF;
                    border: 1px ;
                    border-radius: 8px;
                    "
                  :label="time" 
                  :color="selectedTime === time ? 'blue' : 'white'"
                  text-color="black"
                  @click="selectTime(time)"
                  class="full-width"
                />
              </div>
            </div>
          </div>
        </div>
            </div>
      </q-card-section>
  
      <q-card-actions align="between" class="q-px-md footer">
        <q-btn 
          v-if="currentStep === 2" 
          flat 
          color="primary" 
          label="Back" 
          @click="currentStep = 1" 
          class="back-btn"
        />
        <div class="total-price">
          Total: <span class="price-value">${{ totalPrice.toFixed(2) }}</span>
        </div>
        <q-btn 
        style="              border-radius: 10px !important;
              background-color: #0069ff !important;"
          color="primary" 
          :label="currentStep === 1 ? 'Continue' : 'Book Appointment'" 
          @click="nextStep" 
          class="continue-btn" 
        />
      </q-card-actions>
      </q-card>
    </q-dialog>
  </template>
  
  <script setup lang="ts">
import { ref, computed ,onMounted } from 'vue';

const dateOptions = (date: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Date(date) >= today;
};
onMounted(() => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  selectedDate.value = `${year}/${month}/${day}`;
});

const props = defineProps<{
  isOpen: boolean,
  consultant: {
    name: string,
    profession: string,
    rating: number,
    avatar: string
  }
}>();

const emit = defineEmits(['update:isOpen', 'book']);

const currentStep = ref(1);
const selectedSession = ref('single');
const selectedDate = ref('');
const selectedTime = ref('');

const multiSessions = [
  { count: 2, originalPrice: 38, discountedPrice: 34.90 },
  { count: 3, originalPrice: 54, discountedPrice: 49.90 },
  { count: 4, originalPrice: 64, discountedPrice: 59.90 },
  { count: 5, originalPrice: 84, discountedPrice: 69.90 }
];

const availableTimes = computed(() => {
  // Bugün seçildiyse ve saat 13:00'ten sonraysa, sadece kalan saatleri göster
  if (selectedDate.value === getCurrentDate()) {
    const currentHour = new Date().getHours();
    return ['10:00', '11:00', '13:00', '16:00', '17:00', '18:00', '20:00', '22:00'].filter(time => {
      const [hour] = time.split(':').map(Number);
      return hour > currentHour;
    });
  }
  // Diğer günler için tüm saatleri göster
  return ['10:00', '11:00', '13:00', '16:00', '17:00', '18:00', '20:00', '22:00'];
});

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};

const selectSession = (session: string | number) => {
  selectedSession.value = session.toString();
};

const selectTime = (time: string) => {
  selectedTime.value = time;
};

const totalPrice = computed(() => {
  if (selectedSession.value === 'free') return 0;
  if (selectedSession.value === 'single') return 19.90;
  const session = multiSessions.find(s => s.count === Number(selectedSession.value));
  return session ? session.discountedPrice : 0;
});

const nextStep = () => {
  if (currentStep.value === 1) {
    currentStep.value = 2;
  } else {
    bookAppointment();
  }
};

const bookAppointment = () => {
  emit('book', { 
    session: selectedSession.value, 
    price: totalPrice.value,
    date: selectedDate.value,
    time: selectedTime.value
  });
  emit('update:isOpen', false);
};
</script>
  
  <style scoped>
  .date-time-selection {
  padding: 24px 32px;
  background: #F8FAFC;
    border: 1px solid #F1F5F9;
    border-radius: 16px;
}

.back-btn {
  font-family: 'Red Hat Display';
  font-weight: 700;
  font-size: 12px;
}

@media (max-width: 600px) {
  .date-time-selection {
    padding: 16px;
  }
}
  .appointment-modal {
    width: 100%;
    max-width: 789px;
    border-radius: 16px;
    background: #FFFFFF;
  }
  
  .header {
    height: 73px;
    padding: 24px 32px;
    border-bottom: 1px solid #F1F5F9;
  }
  
  .header .text-h6 {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 18px;
    color: #0F172A;
  }
  
  .close-btn {
    background: #F1F5F9;
    border-radius: 8px;
  }
  
  .consultant-info {
    padding: 24px 32px;
  }
  
  .consultant-details {
    flex-grow: 1;
  }
  
  .consultant-name {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 22px;
    color: #0F172A;
  }
  
  .consultant-profession {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    color: #0F172A;
  }
  
  .consultant-rating {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 16px;
    color: #0F172A;
  }
  
  .bulk-info {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    background: #F8FAFC;
    border: 1px solid #F1F5F9;
    border-radius: 16px;
    padding: 16px;
  }
  
  .general-options-wrapper {
    padding: 24px 32px;
  }
  
  .session-options {
    background: #F8FAFC;
    border: 1px solid #F1F5F9;
    border-radius: 16px;
    padding: 24px;
  }
  
  .session-card {
    border: 1px solid #F1F5F9;
    border-radius: 12px;
    transition: all 0.3s ease;
    position: relative;
    background: #FFFFFF;
    height: 100%;
  }
  
  .selected-session {
    background: #E6F0FF;
    border: 1px solid #0069FF;
  }
  
  .session-duration {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    color: #64748B;
  }
  
  .session-price {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 14px;
    color: #0069FF;
  }
  
  .original-price {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 12px;
    color: #64748B;
    text-decoration: line-through;
  }
  
  .discounted-price {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 14px;
    color: #0069FF;
    margin-left: 4px;
  }
  
  .selected-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #0069FF;
    font-size: 24px;
  }
  
  .savings-banner {
    background: linear-gradient(136.7deg, #BAF7D1 24.26%, rgba(61, 213, 152, 0.7) 87.51%);
    border-radius: 12px;
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 12px;
    color: #0F172A;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .total-price {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 12px;
    color: #94A3B8;
  }
  
  .price-value {
    font-weight: 700;
    color: #0069FF;
    font-size: 18px;
  }
  
  .continue-btn {
    background: #0069FF;
    border-radius: 10px;
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    padding: 8px 16px;
  }
  
  @media (max-width: 600px) {
    .appointment-modal {
      max-width: 100%;
      margin: 16px;
    }
  
    .header {
      padding: 16px;
    }
  
    .consultant-info {
      padding: 16px;
      flex-direction: column;
    }
  
    .bulk-info {
      margin-top: 16px;
      max-width: 100%;
    }
  
    .session-options {
      padding: 16px;
    }
  
    .footer {
      flex-direction: column;
      align-items: stretch;
    }
  
    .total-price {
      margin-bottom: 16px;
    }
  
    .continue-btn {
      width: 100%;
    }
  }
  </style>