import axiosInstance from '@/utils/axios';

export interface RegisterUserDto {
  username: string;
  name: string;
  surname: string;
  email: string;
  primaryLanguage: string;
  phone: string;
  password: string;
  role: string;
}

export const authService = {
  async login(email: string, password: string) {
    try {
      const response = await axiosInstance.post('/auth/login', { email, password });
      return response.data.user;       
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response &&
        error.response.data &&
        typeof error.response.data === 'object' &&
        'message' in error.response.data &&
        typeof error.response.data.message === 'string'
      ) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
    
  },

  async logout() {
    try {
      await axiosInstance.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    }
  },

  async getCurrentUser() {
    try {
      const response = await axiosInstance.get('/auth/current-user');
      return response.data; 
    } catch (error) {
      console.error('Get current user error:', error);
      return null;
    }
  },

  async register(userData: FormData) {
    try {
      const response = await axiosInstance.post('/auth/register', userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: unknown) {
      if (
        error instanceof Error &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response &&
        error.response.data &&
        typeof error.response.data === 'object' &&
        'message' in error.response.data &&
        typeof error.response.data.message === 'string'
      ) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  },
  async forgotPassword(email: string) {
    try {
      const response = await axiosInstance.post('/auth/forgot-password', { email });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async validateResetToken(token: string) {
    try {
      const response = await axiosInstance.post('/auth/validate-reset-token', { token });
      console.log("response",response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async resetPassword(token: string, newPassword: string) {
    try {
      const response = await axiosInstance.post('/auth/reset-password', { token, newPassword });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async sendVerificationEmail(userId: string) {
    try {
      const response = await axiosInstance.post('/auth/send-verification-email', { userId });
      return response.data;
    } catch (error) {
      console.error('Send verification email error:', error);
      throw error;
    }
  },

  async verifyEmail(userId: string, verificationCode: string) {
    try {
      const response = await axiosInstance.post('/auth/verify-email', { userId, verificationCode });
      return response.data;
    } catch (error) {
      console.error('Verify email error:', error);
      throw error;
    }
  },

  async sendPhoneOtp(phone: string) {
    try {
      const response = await axiosInstance.post('/auth/send-otp', { phone });
      return response.data;
    } catch (error) {
      console.error('Send phone OTP error:', error);
      throw error;
    }
  },

  async verifyPhoneOtp(phone: string, code: string) {
    try {
      const response = await axiosInstance.post('/auth/verify-otp', { phone, code });
      return response.data;
    } catch (error) {
      console.error('Verify phone OTP error:', error);
      throw error;
    }
  },

};