<!-- // src/components/ConsultantHorizontalCard.vue  -->

<template>
  <q-card
    class="my-card q-pb-md card-template q-px-lg"
    style="width: 100%"
    flat
    bordered
  >
    <q-card-section horizontal class="q-pt-md q-col-gutter-md">
      <div class="col-md-2 justify-center items-center" style="display: flex">
        <q-img
          class="consultant-image"
          src="https://cdn.quasar.dev/img/parallax2.jpg"
        />
      </div>

      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6 column q-pl-sm items-start">
            <h4 class="consultant-headline q-ma-none">{{ name }}</h4>
            <p class="q-mb-xs">{{ profession }}</p>
            <div class="row q-gutter-sm">
              <span
                style="
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%;
                "
                >4,7</span
              ><span style="display: flex"
                ><q-img
                  style="width: 16px; height: 16px"
                  src="/img/icons/star-solid.svg"
                ></q-img
              ></span>
              <div><span class="consultant-review">(32 reviews)</span></div>
            </div>
          </div>
          <div class="col-md-6 column" style="align-items: flex-end">
            <div class="row text-left justift-start items-start">
              <span
                v-if="price.toString().split('.').length == 1"
                style="
                  font-family: 'Red Hat Display';
                  font-size: 32px;
                  font-weight: 700;
                  line-height: 32px;
                  text-align: right;
                  color: #0069ff;
                "
              >
                ${{ price }}
              </span>
              <div v-if="price.toString().split('.').length != 1">
                <span
                  style="
                    font-family: 'Red Hat Display';
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 32px;
                    text-align: right;
                    color: #0069ff;
                  "
                >
                  ${{ price.toString().split(".")[0] }},
                </span>
                <span
                  style="
                    font-family: 'Red Hat Display';
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                    margin-left: -4px;
                    color: #0069ff;
                  "
                >
                  {{ price.toString().split(".")[1] }}
                </span>
              </div>
            </div>
            <p class="consultant-session">
              (Video 45 min./ Voice Call 45 min.)
            </p>
          </div>
        </div>
        <div class="row q-mt-md">
          <q-chip
            style="background: var(--greyscale-100, #f1f5f9)"
            v-for="item in items"
            ><span class="consultant-chip">{{ item }}</span></q-chip
          >
        </div>
        <div class="row q-mt-md">
          <div class="col-md-4 offset-md-3">
            <q-btn
              style="width: 95%"
              class="white-button"
              text-color="black"
              label="See Details"
            />
          </div>
          <div class="col-md-4">
            <q-btn
              style="width: 95%"
              class="blue-button"
              label="Book Appointments"
            />
          </div>
          <div class="col-md-1">
            <q-btn
              style="width: 90%"
              class="gray-button"
              icon="img:img/icons/share.svg"
            />
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script setup lang="ts">
defineProps({
  name: {
    type: String,
    required: true,
  },
  profession: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
});
const items = [
  "Anxiety Disorders",
  "Child Development",
  "Relationship Counseling",
  "Marriage Counseling",
  "Depression",
];
</script>
<style>
.card-template {
  border-radius: 16px !important;
  border: 1px solid var(--greyscale-200, #e2e8f0) !important;
  background: var(--additional-white, #fff) !important;
}
.consultant-headline {
  color: var(--greyscale-900, #0f172a);

  /* Headline 4 */
  font-family: Red Hat Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 33px */
  margin-bottom: 3px;
}

.consultant-review {
  color: var(--secondary-algal-fuel-400, #53ea8b);
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-decoration-line: underline;
}

.consultant-price {
  color: var(--primary-600-base, #0069ff);
  text-align: right;
  font-family: Red Hat Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 32px */
}

.consultant-session {
  color: var(--greyscale-500, #64748b);
  text-align: right;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}

.consultant-image {
  width: 80%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid var(--greyscale-100, #f1f5f9);
}

.consultant-chip {
  color: var(--greyscale-400, #94a3b8);
  font-family: Red Hat Display;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
}
.blue-button {
  border-radius: 10px !important;
  background: var(--primary-600-base, #0069ff) !important;
  color: var(--greyscale-900, #0f172a) !important;

  /* Body Small (Bold) */
  font-family: Red Hat Display;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 125% !important; /* 15px */
}
.white-button {
  border-radius: 10px !important;
  border: 1px solid var(--greyscale-100, #f1f5f9) !important;
  background: var(--additional-white, #fff) !important;
  color: var(--greyscale-900, #0f172a) !important;

  /* Body Small (Bold) */
  font-family: Red Hat Display !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 125% !important; /* 15px */
}

.gray-button {
  border-radius: 8px !important;
  background: var(--greyscale-100, #f1f5f9) !important;
  color: var(--greyscale-900, #0f172a) !important;

  /* Body Small (Bold) */
  font-family: Red Hat Display !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 125% !important; /* 15px */
}
</style>
