import script from "./PublicFooter.vue?vue&type=script&setup=true&lang=ts"
export * from "./PublicFooter.vue?vue&type=script&setup=true&lang=ts"

import "./PublicFooter.vue?vue&type=style&index=0&id=fed6c3c4&lang=css"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QImg,QBtn,QItem,QItemSection,QSeparator});
