<!-- // src/components/Global/PublicLayout.vue -->

<template>
  <div class="container">
    <nav
      class="public-navigation"
      style="position: absolute; z-index: 1"
    >
      <div class="logo-and-links">
        <router-link to="/">
          <q-img
            src="/img/MSLogo.svg"
            alt="ms-logo-header"
            width="150px"
            height="41px"
          />
        </router-link>
        <div class="nav-links">
          <router-link class="q-pl-md" to="/blogs">Blogs</router-link>
          <router-link to="/about">About</router-link>
          <router-link to="/faq">FAQ</router-link>

          <router-link v-if="userStore.profile?.role !== 'consultant'" to="/consultant-application">Apply as Consultant</router-link>
          <!-- <q-btn @click="goToProfileSettings" v-if="userStore.$state.profile?.role === 'consultant' && (!userStore.$state.profile?.isPhoneVerified || !userStore.profile?.isEmailVerified)" color="primary" >Complete your profile</q-btn> -->
        </div>
      </div>
    </nav>
    <nav
      class="public-navigation-right"
      style="
        position: absolute;
        background: transparent;
        z-index: 999;
        right: 2%;
      "
    >
    <div class="sign-buttons" v-if="!userStore.$state.isAuthenticated">
        <q-btn
          to="/register"
          color="primary"
          label="Sign Up"
          class="text-capitalize header-sign-up q-px-lg q-py-xs"
        />
        <q-btn
          to="/login"
          class="header-sign-in text-capitalize q-px-lg q-py-xs q-ml-sm"
          outline
          color="white"
          label="Sign In"
        />
    </div>
      <div class="sign-buttons" v-else>
        <q-btn
          @click="goToDashboard"
          color="primary"
          label="Dashboard"
          class="text-capitalize header-dashboard q-px-s q-py-xs"
        />
        <q-btn
          @click="logout"
          class="header-sign-out text-capitalize q-px-s q-py-xs q-ml-sm"
          outline
          color="white"
          label="Sign Out"
        />
      </div>
    </nav>
  </div>

  <router-view></router-view>
  <PublicFooter></PublicFooter>
</template>

<script setup lang="ts">
import PublicFooter from "../PublicFooter.vue";
import { useUserStore } from "@/store/user";
import { useRouter } from "vue-router";
import { REDIRECT_URL } from '@/utils';

const userStore = useUserStore();
const router = useRouter();

const goToProfileSettings = () => {
  const role = userStore.profile?.role;
  let profileSettingsUrl:string="";
  if (role ==='consultant') {
    profileSettingsUrl = REDIRECT_URL+'/consultant/settings';
  } 

  window.location.href = profileSettingsUrl;
};

const goToDashboard = () => {
  const role = userStore.profile?.role;
  let dashboardUrl = REDIRECT_URL;
  if (role === 'admin' || role === 'superAdmin') {
    dashboardUrl += '/admin';
  } else if (role === 'consultant') {
    dashboardUrl += '/consultant';
  } else {
    dashboardUrl += '/client';
  }
  window.location.href = dashboardUrl;
};

const logout = async () => {
  await userStore.logout();
  router.push('/');
};
</script>

<style>
.public-navigation {
  padding: 30px 30px 30px 0px;
}

.public-navigation-right {
  padding: 30px 30px 30px 0px;
}

.public-navigation a {
  color: #f9f9f9;
  padding-right: 48px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
}

.header-sign-up {
  border-radius: 16px !important;
  background-color: #0069ff;
}
.header-dashboard {
  border-radius: 16px !important;
  background-color: #0069ff;
}

.header-sign-in {
  border-radius: 16px !important;
  border: #e2e8f0 !important;
}
.header-sign-out {
  border-radius: 16px !important;
  border: #e2e8f0 !important;
}

@media screen and (max-width: 768px) {
  

}

@media screen and (max-width: 468px) {
  .nav-links {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 10px;
  }
}
</style>
