//src/store/profession/index.ts

import axiosInstance from "@/utils/axios";
import { defineStore } from "pinia";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { reactive } from "vue";

interface IProfessionListingItem {
  _id: string;
  name: string;
  subProfessions: { name: string }[];
}

interface IState {
  professions: IProfessionListingItem[];
  errorMessage: null | string;
  profession: IProfessionListingItem;
}

interface IProfessionCreateDto {
  name: string;
}

interface ISubProfessionCreateDto {
  name: string;
  professionId: string;
}

interface IProfessionTitleCreateDto {
  name: string;
  professionId: string;
}

interface IIdBody {
  id: string;
}

export const useProfessionStore = defineStore("profession", () => {
  const $q = useQuasar();

  const state = reactive<IState>({
    professions: [] as IProfessionListingItem[],
    errorMessage: null,
    profession: {} as IProfessionListingItem,
  });

  const createProfession = async ({ name }: IProfessionCreateDto) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      const data = await axiosInstance.post("/profession/create", { name });
      $q.loading.hide();
      return data.data;
    } catch (error) {
      state.errorMessage = "Error occured while creating profession.";
      $q.loading.hide();
    }
  };

  const createSubProfession = async (
    createSubProfessionDto: ISubProfessionCreateDto[]
  ) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      await axiosInstance.post(
        "/sub-profession/create-many",
        createSubProfessionDto
      );
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while creating sub-profession.";
      $q.loading.hide();
    }
  };

  const createProfessionTitle = async (
    createProfessionTitleDto: IProfessionTitleCreateDto[]
  ) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      await axiosInstance.post(
        "/profession-title/create-many",
        createProfessionTitleDto
      );
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while creating profession title.";
      $q.loading.hide();
    }
  };

  const getProfessions = async () => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      const profs = await axiosInstance.get("/profession");
      state.professions = profs.data;
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while profession list.";
      $q.loading.hide();
    }
  };

  const deleteProfession = async ({ id }: IIdBody) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      await axiosInstance.delete(`/profession/${id}`);
      state.professions = state.professions.filter((e) => e._id != id);
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while deleting profession.";
      $q.loading.hide();
    }
  };

  const deleteSubProfession = async ({ id }: IIdBody) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      await axiosInstance.delete(`/sub-profession/${id}`);
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while deleting sub-profession.";
      $q.loading.hide();
    }
  };

  const deleteProfessionTitle = async ({ id }: IIdBody) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      await axiosInstance.delete(`/profession-title/${id}`);
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while deleting profession title.";
      $q.loading.hide();
    }
  };

  const getProfession = async ({ id }: IIdBody) => {
    $q.loading.show();
    state.errorMessage = null;
    try {
      const profs = await axiosInstance.get(`/profession/${id}`);
      state.profession = profs.data;
      $q.loading.hide();
    } catch (error) {
      state.errorMessage = "Error occured while getting profession.";
      $q.loading.hide();
    }
  };

  const removeErrorMessage = () => {
    state.errorMessage = null;
  };

  return {
    state,
    createProfession,
    createSubProfession,
    removeErrorMessage,
    createProfessionTitle,
    getProfessions,
    deleteProfession,
    deleteSubProfession,
    deleteProfessionTitle,
    getProfession
  };
});
