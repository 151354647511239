<!-- // src/views/Public/RegisterView.vue -->
<template>
  <div class="row">
    <div class="col-md-6 gt-sm">
      <AuthLeftSwiper imageUrl="/img/YogaRegister.png" />
    </div>
    <div
      class="col-md-6 col-sm-12 col-xs-12 q-pa-xl"
      style="
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <!-- <RegisterRoleComp :selectedRole="registerRole" :onRoleClicked="changeRegisterRole"/> -->
      <div
        
        style="
          text-align: start;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <span
          style="
            font-family: 'Red Hat Display';
            font-size: 24px;
            font-weight: 700;
            line-height: 150%;
            text-align: start;
            color: #1e293b;
          "
        >
          Sign Up to your Account
        </span>
        <span
          class="q-mt-sm"
          style="
            font-family: 'Red Hat Display';
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            text-align: start;
            color: #64748b;
          "
        >
          Welcome back! please enter your detail
        </span>
        <q-input
          class="q-mt-xl"
          v-model="name"
          placeholder="Name"
          borderless
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
          "
        >
        
          <template v-slot:prepend>
            <q-img
              src="/img/icons/user.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>        
        <q-input
          class="q-mt-xl"
          v-model="surname"
          placeholder="Surname"
          borderless
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
          "
        >
        
          <template v-slot:prepend>
            <q-img
              src="/img/icons/user.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>
        <q-input
          class="q-mt-xl"
          v-model="email"
          placeholder="Email"
          borderless
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
          "
        >
        
          <template v-slot:prepend>
            <q-img
              src="/img/icons/mailLight.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>
        <div v-if="userStore.$state.errorMessage != null" style="margin-top: 16px">
          <q-img
            src="/img/icons/alertWarningRed.svg"
            alt=""
            width="16px"
            height="16px"
          />
          <span
            style="
              font-family: 'Red Hat Display';
              font-size: 12px;
              font-weight: 400;
              color: #ff6155;
              margin-left: 4px;
            "
          >
            {{ userStore.$state.errorMessage }}
          </span>
        </div>
        <q-input
          v-model="password"
          placeholder="Password"
          type="password"
          borderless
          :style="{
            border: '1px solid #e2e8f0',
            borderRadius: '16px',
            paddingLeft: '16px',
            fontFamily: 'Red Hat Display',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '150%',
            color: '#94a3b8',
            marginTop: userStore.$state.errorMessage == null ? '32px' : '16px',
          }"
        >
          <template v-slot:prepend>
            <q-img
              src="/img/icons/lock.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
        <q-checkbox
            class="q-mt-lg"
            v-model="rememberMe"
            size="md"
            label="I agree to the Terms & Conditions"
            style="
              font-family: 'Red Hat Display';
              font-size: 12px;
              font-weight: 500;
              line-height: 100%;
              text-align: start;
              color: #94a3b8;
            "
          />
          
          <router-link
          to="/forgot-password"
          style="
            font-family: 'Red Hat Display';
            font-size: 14px;
            font-weight: 700;
            line-height: 150%;
            text-align: end;
            cursor: pointer;
            margin-top: 30px;
            color: #0069ff;
            text-decoration: none;
          "
        >
          Forgot Password?
        </router-link>
        </div>

        <div>
          <q-checkbox
            class="q-mt-lg"
            v-model="adult"
            size="md"
            label="I am older than 18 years old"
            style="
              font-family: 'Red Hat Display';
              font-size: 12px;
              font-weight: 500;
              line-height: 100%;
              text-align: start;
              color: #94a3b8;
            "
          />
        </div>
        <q-btn
          label="Sign Up"
          style="
            background-color: #0069ff;
            color: #ffffff;
            font-family: Red Hat Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: none;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 16px;
            margin-top: 32px;
          "
          @click="register"
          :disable="
            email.length == 0 ||
            email.includes('@') == false ||
            password.length == 0
              ? true
              : false
          "
        />
      </div>
      <span
        class="q-mt-lg"
        style="
          font-family: 'Red Hat Display';
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          text-align: center;
          color: #64748b;
        "
      >
        Already have an account?
        <router-link to="/login" style="color: #0069ff; font-weight: 700; text-decoration: none;">Sign In</router-link>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, onMounted } from "vue";
import { AuthLeftSwiper } from "@/components";
import { useUserStore } from "@/store/user";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";

const userStore = useUserStore();
const router = useRouter();
const $q = useQuasar();

const name = ref<string>("");
const surname = ref<string>("");
const email = ref<string>("");
const password = ref<string>("");
const primaryLanguage = ref<string>("");
const phone = ref<string>("");
const rememberMe = ref<boolean>(false);
const adult = ref<boolean>(false);

onMounted(() => {
  userStore.removeErrorMessage();
});

watchEffect(() => {
  if (userStore.isAuthenticated && userStore.profile?.role) {
    const role = userStore.profile.role;
    if (role === 'admin' || role === 'superAdmin') {
      router.push("/admin/dashboard/home");
    } else if (role === 'consultant') {
      router.push("/consultant/dashboard/home");
    } else {
      router.push("/patient/dashboard/home");
    }
  }
});

const register = async () => {
  $q.loading.show();
  try {
    const formData = new FormData();
    formData.append('name', name.value); // 'name' özelliğini FormData'ya ekleyin
    formData.append('surname', surname.value);
    formData.append('email', email.value);
    formData.append('password', password.value);
    formData.append('role', 'patient');

    await userStore.register(formData); // FormData nesnesini gönderin

    $q.notify({
      type: 'positive',
      message: 'Registration successful! Enjoy the mindfullness journey.',
      position: 'top'
    });
    router.push('/login');
  } catch (error) {
    console.error('Registration error:', error);
    $q.notify({
      type: 'negative',
      message: userStore.errorMessage || 'Registration failed. Please try again.',
      position: 'top'
    });
  } finally {
    $q.loading.hide();
  }
};
</script>

<style></style>


