// src/main.ts 
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "./styles/global.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import PrimeVue from 'primevue/config';
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueGtag from "vue-gtag";
import VueApexCharts from "vue3-apexcharts";
import '@quasar/quasar-ui-qcalendar/dist/index.css';
import { QCalendar } from '@quasar/quasar-ui-qcalendar';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// createApp(App)
//   .use(Quasar, quasarUserOptions)
//   .use(router)
//   .use(pinia)
//   .use(VueApexCharts)
//   .use(VueGtag, {
//     appName: 'Minimum Stress',
//     pageTrackerScreenviewEnabled: true,
//     config: { 
//       id: "G-NFL975LTSQ",
//     },
//   }, router)
//   .mount("#app");

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(pinia)
  .use(router)
  .use(PrimeVue)
  .use(VueApexCharts)
  .component("QCalendar", QCalendar)
  .mount("#app");


