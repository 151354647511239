<!-- // src/App.vue  -->

<template>
  <!-- <PublicLayout/> -->
  <!-- <AdminLayout /> -->

  <component :is="$route.meta.layout || 'div'">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { isAuthenticated } = storeToRefs(userStore);

onMounted(async () => {
  if (isAuthenticated.value) {
    await userStore.fetchCurrentUser();
    
  }
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
