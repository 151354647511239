<!-- // src/components/TestimonialCard.vue -->
<template>
  <q-card style="box-shadow: none">
    <div class="row q-qutter-x-md">
      <div class="col-1">
        <div class="column avatar-container items-center justify-center" rounded>
          <img
            v-if="avatarUrl"
            :src="avatarUrl"
            class="avatar-image"
            alt="Avatar"
          />
          <span v-else class="avatar-placeholder">{{ getInitials(name) }}</span>
        </div>
      </div>
      <div class="col-11 flex items-start column">
        <p
          :style="{
            fontWeight: 500,
            color: '#000',
          }"
          class="comment-name"
        >
          {{ name }}
        </p>
        <p class="comment-content-soft q-ma-none" style="text-align: left">
          {{ content }}
        </p>
      </div>
    </div>
  </q-card>
</template>

<script setup lang="ts">
interface Props {
  name: string;
  content: string;
  avatarUrl?: string;
}

const props = withDefaults(defineProps<Props>(), {
  avatarUrl: undefined
});

function getInitials(name: string): string {
  return name.split(' ').map(word => word[0]).join('').toUpperCase().slice(0, 2);
}
</script>

<style>
.comment-date {
  color: #475569;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.comment-name {
  font-size: 16px;
  font-style: normal;
  line-height: 150%; /* 24px */
  margin: 0;
}
.comment-content {
  color: #7c7c7c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.comment-content-soft {
  color: #7c7c7c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.star-avatar {
  border-radius: 16px !important;
  border: 1px solid var(--greyscale-200, #e2e8f0);
  background: var(--greyscale-50, #f8fafc);

  height: 64px !important;
  width: 64px !important;
}
.star-avatar .row {
  flex-direction: column !important;
}

.star-value {
  color: var(--greyscale-700, #334155);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
}

.avatar-container {
  border-radius: 16px !important;
  border: 1px solid var(--greyscale-200, #e2e8f0);
  background: var(--greyscale-50, #f8fafc);
  height: 64px !important;
  width: 64px !important;
  overflow: hidden;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  color: var(--greyscale-700, #334155);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
