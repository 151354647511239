// src/quasar-user-options.ts
import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import "quasar/dist/quasar.addon.css";
import Notify from 'quasar/src/plugins/notify/Notify.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';import Dark from 'quasar/src/plugins/dark/Dark.js';import Screen from 'quasar/src/plugins/screen/Screen.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: { screen: { bodyClasses: true } },
  plugins: {
    Notify,
    Cookies,
    Dark,
    Screen,
    Loading,
    Dialog
  },
};
