<!-- // src/components/PublicFaqItem.vue -->
<template>
    <q-expansion-item :label="title" expand-icon="img:img/icons/plus.svg" expanded-icon="img:img/icons/minus.svg"
        class="nohover" header-style="
        font-family: Red Hat Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        pointer-events:none
        color:#1E293B"
        style="border-radius: 16px; background-color: #F1F5F9;padding:11px; text-align: start; font-family: 'Red Hat Display'; font-size: 16px; font-weight: 500; line-height: 150%;">
        <q-card-section>
            {{ description }}
            <a :href="link" v-if="link" style="color: #ff6155; text-decoration: underline;">Apply Here</a>
        </q-card-section>
    </q-expansion-item>
</template>

<script setup lang="ts">
document.querySelector(".nohover .q-hoverable")?.classList.remove("q-hoverable")

defineProps({
    title: String,
    description: String,
    link: String
})
</script>
