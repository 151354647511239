import script from "./VerificationDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./VerificationDialog.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QInput,QBtn,QCardActions});qInstall(script, 'directives', {ClosePopup});
