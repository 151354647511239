<!-- // src/views/Public/BlogDetail.vue -->
<template>
  <div style="position: relative">
    <img
      alt="Snow"
      src="/img/HeaderPattern.svg"
      style="width: 100%; height: 500px; background-color: #1e293b;';"
    />
    <div
      class="container"
      style="position: absolute; bottom: 56px; left: 0; right: 0"
    >
      <div class="row">
        <div class="col-12 q-mb-md">
          <PublicColoredLineHeader color="#0069FF" text="Blog" />
        </div>
        <div class="col-md-8">
          <div
            class="text-h3 text-weight-bold text-left"
            style="color: var(--greyscale-50, #f8fafc)"
          >
            {{ blogPost?.title }}
          </div>
        </div>
        <div class="col-12 col-md-4 text-white q-gutter-y-md">
          <div class="row items-center justify-between q-col-gutter-x-md">
            <q-avatar size="80px" class="q-mb-sm" rounded>
              <img :src="blogPost?.consultant?.avatar" />
            </q-avatar>
            <div class="column">
              <div class="text-body1 text-weight-bold">
                {{ blogPost?.consultant?.name }} {{ blogPost?.consultant?.surname }}
              </div>
              <div class="text-body2">
                {{ new Date(blogPost?.createdAt).toLocaleDateString() }}
              </div>
            </div>
          </div>
          <q-btn
            class="full-width"
            style="
              background-color: #0069ff !important;
              border-radius: 10px;
            "
            color="primary"
            label="Book Appointments"
            icon="img:/img/icons/date.svg"
          />
        </div>
      </div>
    </div>
  </div>
  <section class="container" style="margin-top: 65px">
    <div
      class="text-left"
      style="
        color: var(--greyscale-900, #0f172a);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      "
    >
      {{ blogPost?.title }}
    </div>
    <div class="text-left">
      <img
        v-if="blogPost?.image"
        :src="blogPost.image"
        :alt="blogPost.title"
        class="blog-main-image q-my-md"
      />
      <div class="text-body1 q-mt-md" v-html="blogPost?.content"></div>
    </div>
    
  </section>

  <section v-if="totalComments > 0" class="container q-mt-xl q-mb-lg">
      <q-separator class="q-mx-none q-mt-md" inset />
    <div class="flex q-gutter-sm items-center q-mb-md">
      <span class="text-h5 text-weight-bold" style="color: #0f172a">
        Comments
      </span>
      <span
        style="
          color: #475569;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
        "
        >({{ totalComments }})</span>
    </div>
    <div>
      <div v-for="comment in comments" :key="comment._id" class="q-mb-md">
  
            <TestimonialCard
        :name="comment.user.name + ' ' + comment.user.surname"
        :content="comment.comment"
        :avatarUrl="comment.user.avatar"
      ></TestimonialCard>
        
      </div>
      <!-- <q-separator class="q-mx-none q-mt-md" inset /> -->

      <q-btn
        v-if="hasMoreComments"
        class="q-mt-md text-capitalize"
        outline
        style="
          color: #34d399;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="Load More Comments"
        icon="img:/img/icons/plus.svg"
        @click="loadMoreComments"
      />
    </div>
  </section>
  <q-separator class="q-mx-none q-mt-md" inset />
  <section class="container q-mt-xl q-mb-lg">
  <CreateCommentCard 
    v-if="blogPost" 
    :blogId="blogPost._id" 
    @commentCreated="handleNewComment" 
  />
</section>
  <q-separator class="q-mx-none q-mt-md" inset />
  <section v-if="consultantPosts.length > 0" class="container q-mb-xl">
  <div class="text-h5 text-weight-bold text-left" style="color: #0f172a; margin-top: 2%;">
    Posts from {{ blogPost?.consultant.name }}  {{ blogPost?.consultant.surname }} 
  </div>
  <div class="row q-mt-md q-col-gutter-x-lg q-col-gutter-y-md">
    <div v-for="post in consultantPosts" :key="post._id" class="col-md-4">
      <BlogListingItem
        :id="post._id"
        :title="post.title"
        :text="stripHtml(post.content).substring(0, 150) + '...'"
        :coverImageUrl="post.image"
        :userImage="post.consultant.avatar"
        :userName="post.consultant.name + ' ' + post.consultant.surname"
        :date="new Date(post.createdAt).toLocaleDateString()"
        :tag="post.category?.name"
  />
    </div>
  </div>
  <div v-if="hasMorePosts" class="flex justify-center q-mt-md">
    <q-btn
      class="text-capitalize"
      outline
      style="
        color: #0069ff;
        border-radius: 16px;
        padding: 8px;
        width: 236px;
        height: 48px;
      "
      label="Load More Blogs"
      icon="img:/img/icons/plus.svg"
      @click="loadMoreBlogs"
    />
  </div>
</section>
<section v-if="relatedPosts.length > 0" class="container q-mb-xl">
  <div class="text-h5 text-weight-bold text-left" style="color: #0f172a">
    Related Posts
  </div>
  <div class="row q-mt-md q-col-gutter-x-lg q-col-gutter-y-md">
    <div v-for="post in relatedPosts" :key="post._id" class="col-md-4">
      <BlogListingItem
          :id="post._id"
          :title="post.title"
          :text="stripHtml(post.content).substring(0, 150) + '...'"
          :coverImageUrl="post.image"
          :userImage="post.consultant.avatar"
          :userName="post.consultant.name + ' ' + post.consultant.surname"
          :date="new Date(post.createdAt).toLocaleDateString()"
          :tag="post.category"
        />
    </div>
  </div>
  <div v-if="hasMoreRelatedPosts" class="flex justify-center q-mt-md">
    <q-btn
      class="text-capitalize"
      outline
      style="
        color: #0069ff;
        border-radius: 16px;
        padding: 8px;
        width: 236px;
        height: 48px;
      "
      label="Load More Related Posts"
      icon="img:/img/icons/plus.svg"
      @click="loadMoreRelatedPosts"
    />
  </div>
</section>
</template>

<script setup lang="ts">
import { PublicColoredLineHeader,CreateCommentCard,TestimonialCard, BlogListingItem } from "@/components";
import { useRoute } from 'vue-router';
import { ref, watch,onMounted} from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/utils/config';
export interface FilterMetadata {
  value: string | null;
  matchMode: string;
  operator: string;
}

export interface QueryDto {
  skip: string;
  limit: string;
  filters?: {
    [key: string]: FilterMetadata[];
  };
  sortField?: string;
  sortOrder?: string;
  globalFilter?: string;
  columns?: string;
  populateOptions?: { [key: string]: string[] };
}

const route = useRoute();
const blogPost = ref(null);
const consultantPosts = ref([]);
const currentPage = ref(1);
const pageSize = 3;
const hasMorePosts = ref(true);
const relatedPosts = ref([]);
const relatedCurrentPage = ref(1);
const relatedPageSize = 3;
const hasMoreRelatedPosts = ref(true);
const comments = ref([]);
const currentCommentPage = ref(1);
const commentPageSize = 2;
const hasMoreComments = ref(true);
const totalComments = ref(0);
const handleNewComment = (newComment) => {
  loadComments(); 
};

const stripHtml = (html: string): string => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const fetchBlogPost = async (id: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/blog/${id}`, {
      params: { status: 'approved' }
    });
    blogPost.value = response.data;
    await loadConsultantPosts();
    await loadRelatedPosts();
    await loadComments();
  } catch (error) {
    console.error('Blog gönderisi alınırken hata oluştu:', error);
  }
};

watch(() => route.params.id, (newId) => {
  if (newId) {
    consultantPosts.value = [];
    relatedPosts.value = [];
    currentPage.value = 1;
    relatedCurrentPage.value = 1;
    hasMorePosts.value = true;
    hasMoreRelatedPosts.value = true;
    fetchBlogPost(newId as string);
  }
}, { immediate: true });


const createRelatedQueryDto = (page: number): QueryDto => ({
  skip: ((page - 1) * relatedPageSize).toString(),
  limit: relatedPageSize.toString(),
  filters: {
    _id: [{ value: route.params.id as string, matchMode: 'notEquals', operator: 'and' }],
    category: [{ value: blogPost.value?.category?._id, matchMode: 'equals', operator: 'and' }],
    status: [{ value: 'approved', matchMode: 'equals', operator: 'and' }]
  },
  sortField: 'createdAt',
  sortOrder: '-1'
});

const createQueryDto = (page: number): QueryDto => ({
  skip: ((page - 1) * pageSize).toString(),
  limit: pageSize.toString(),
  filters: {
    _id: [{ value: route.params.id as string, matchMode: 'notEquals', operator: 'and' }],
    status: [{ value: 'approved', matchMode: 'equals', operator: 'and' }]
  },
  sortField: 'createdAt',
  sortOrder: '-1'
});

const createCommentQueryDto = (page: number): QueryDto => ({
  skip: ((page - 1) * commentPageSize).toString(),
  limit: commentPageSize.toString(),
  sortField: 'createdAt',
  sortOrder: '-1'
});

const loadMoreBlogs = async () => {
  currentPage.value++;
  await loadConsultantPosts();
};

const loadConsultantPosts = async () => {
  if (!blogPost.value || !blogPost.value.consultant) return;

  const consultantId = blogPost.value.consultant._id;
  const queryDto = createQueryDto(currentPage.value);

  try {
    const response = await axios.get(`${API_BASE_URL}/blog/by-consultant/${consultantId}`, { params: queryDto });
    const newPosts = response.data.data;
    
    if (newPosts.length < pageSize) {
      hasMorePosts.value = false;
    }

    consultantPosts.value = [...consultantPosts.value, ...newPosts];
  } catch (error) {
    console.error('Consultant blogları yüklenirken hata oluştu:', error);
  }
};
const loadRelatedPosts = async () => {
  if (!blogPost.value || !blogPost.value.category) return;

  const queryDto = createRelatedQueryDto(relatedCurrentPage.value);

  try {
    const response = await axios.get(`${API_BASE_URL}/blog`, { params: queryDto });
    const newPosts = response.data.data;
    
    if (newPosts.length < relatedPageSize) {
      hasMoreRelatedPosts.value = false;
    }

    relatedPosts.value = [...relatedPosts.value, ...newPosts];
  } catch (error) {
    console.error('Related bloglar yüklenirken hata oluştu:', error);
  }
};
const loadMoreRelatedPosts = async () => {
  relatedCurrentPage.value++;
  await loadRelatedPosts();
};

const loadComments = async () => {
  if (!blogPost.value) return;

  const queryDto = createCommentQueryDto(currentCommentPage.value);

  try {
    const response = await axios.get(`${API_BASE_URL}/blog-comment/blog/${blogPost.value._id}`, { params: queryDto });
    const newComments = response.data.data;
    totalComments.value = response.data.meta.totalCount;
    
    if (newComments.length < commentPageSize) {
      hasMoreComments.value = false;
    }

    if (currentCommentPage.value === 1) {
      comments.value = newComments;
    } else {
      comments.value = [...comments.value, ...newComments];
    }
  } catch (error) {
    console.error('Yorumlar yüklenirken hata oluştu:', error);
  }
};

const loadMoreComments = async () => {
  currentCommentPage.value++;
  await loadComments();
};

watch(() => blogPost.value, (newBlogPost) => {
  if (newBlogPost) {
    comments.value = [];
    currentCommentPage.value = 1;
    hasMoreComments.value = true;
    loadComments();
  }
});
</script>

<style scoped>
.blog-main-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .q-avatar {
    margin-bottom: 16px;
  }
}
</style>

