<!-- // src/components/BlogListingItem.vue  -->

<template>
    
    <div @click="navigateToBlogDetail" class="cursor-pointer" style="border:1px solid #E2E8F0; border-radius: 16px; background-color: #F8FAFC;">
        <q-img :src="coverImageUrl" alt=""
            style="border-top-left-radius: 16px; border-top-right-radius: 16px; max-height: 143px;" spinner-color="blue" />
        <div class="q-mt-lg q-pl-md q-pr-md" style="text-align: start;">
            <span style="font-family: 'Red Hat Display';
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 18px;
                        text-align: start;
                        cursor: pointer;
                        color:#0F172A">
                {{ title }}
            </span>
        </div>
        <div class="q-pl-md q-pr-md q-mt-sm">
            <span style="font-family: 'Red Hat Display';
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        text-align: start;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis; 
                        display: -webkit-box;  
                        -webkit-line-clamp: 3; 
                        -webkit-box-orient: vertical;
                        color:#64748B">
                {{ text }}
            </span>
        </div>
        <q-separator inset class="q-mt-md q-mb-md" style="background-color: #E2E8F0; height: 2px;"/>
        <div class="q-pl-md q-pr-md q-mb-lg" style="display: flex; align-items: center;">
            <q-img :src="userImage" alt="" style="width: 48px; height: 48px; border-radius: 8px; border: 1px solid var(--greyscale-100, #F1F5F9);"/>
            <div class="q-ml-md" style="display: flex; flex-direction: column;">
                <span style="font-family: 'Red Hat Display';
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 150%;
                        text-align: start;
                        color:#1E293B">
                    {{ userName }}
                </span>
                <span style="font-family: 'Red Hat Display';
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 150%;
                        text-align: start;
                        color:#64748B">
                    {{ date }} - {{ tag }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    id: String,
    title: String,
    coverImageUrl: String,
    text: String,
    userImage: String,
    userName: String,
    date: String,
    tag: String
});

const navigateToBlogDetail = () => {
    if (props.id) {
        router.push({ name: 'BlogDetail', params: { id: props.id } });
    }
};
</script>