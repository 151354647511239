<template>
    <div class="row justify-center items-center" style="min-height: 100vh; background-color: #F8FAFC;">
      <div class="col-md-6 col-sm-12 col-xs-12 q-pa-xl">
        <div class="text-center">
          <q-icon name="check_circle" color="positive" size="6rem" />
          <h2 class="text-h4 q-mt-md" style="font-family: 'Red Hat Display'; font-weight: 700; color: #1E293B;">
            Application Submitted Successfully!
          </h2>
          <p class="q-mt-md" style="font-family: 'Red Hat Display'; font-size: 16px; color: #64748B;">
            Thank you for your interest in joining our team. We have received your application and will review it shortly.
          </p>
          <p class="q-mt-md" style="font-family: 'Red Hat Display'; font-size: 16px; color: #64748B;">
            We will contact you via email at <strong>{{ email }}</strong> with further information about your application status.
          </p>
          <q-btn
            label="Return to Home"
            color="primary"
            class="q-mt-xl"
            style="
              font-family: 'Red Hat Display';
              font-size: 16px;
              font-weight: 700;
              text-transform: none;
              padding: 12px 24px;
              border-radius: 16px;
            "
            @click="goToHome"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  
  const router = useRouter();
  const email = ref(''); // Bu değer, başvuru formundan alınmalı
  
  // Ana sayfaya yönlendirme fonksiyonu
  const goToHome = () => {
    router.push('/');
  };
  
  // Eğer email bilgisi route üzerinden geliyorsa:
  // const route = useRoute();
  // email.value = route.params.email as string;
  </script>