<!-- // src/components/Group.vue -->
<template>
  <div class="blog-post-list">
    <div class="main-post" v-if="posts.length > 0">
      <BlogListingItem
        :id="posts[0]._id"
        :title="posts[0].title"
        :text="stripHtml(posts[0].content).substring(0, 600) + '...'"
        :coverImageUrl="posts[0].image"
        :userImage="posts[0].consultant.avatar"
        :userName="posts[0].consultant.name + ' ' + posts[0].consultant.surname"
        :date="new Date(posts[0].createdAt).toLocaleDateString()"
        :tag="posts[0].category?.name"
        :isLarge="true"
      />
    </div>
    <div class="related-posts">
      <div v-for="post in posts.slice(1)" :key="post._id" class="related-post">
        <BlogListingItem
          :id="post._id"
          :title="post.title"
          :text="stripHtml(post.content).substring(0, 150) + '...'"
          :coverImageUrl="post.image"
          :userImage="post.consultant.avatar"
          :userName="post.consultant.name + ' ' + post.consultant.surname"
          :date="new Date(post.createdAt).toLocaleDateString()"
          :tag="post.category?.name"
        />
      </div>
    </div>
    <div v-if="hasMorePosts" class="load-more">
      <q-btn
        class="text-capitalize"
        outline
        style="
          color: #0069ff;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="Load More Blogs"
        icon="img:/img/icons/plus.svg"
        @click="$emit('loadMore')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import BlogListingItem from './BlogListingItem.vue';

interface BlogPost {
  _id: string;
  title: string;
  content: string;
  image: string;
  consultant: {
    avatar: string;
    name: string;
    surname: string;
  };
  createdAt: string;
  category?: {
    name: string;
  };
}

export default defineComponent({
  name: 'BlogPostList',
  components: {
    BlogListingItem,
  },
  props: {
    posts: {
      type: Array as PropType<BlogPost[]>,
      required: true,
    },
    hasMorePosts: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['loadMore'],
  methods: {
    stripHtml(html: string): string {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    }
  }
});
</script>

<style scoped>
.blog-post-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-post {
  width: 100%;
}

.related-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.load-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .related-posts {
    grid-template-columns: 1fr;
  }
}
</style>