<template>
  <div class="profile-upload q-mb-md">
    <q-file
      v-model="localProfilePhoto"
      @update:model-value="onProfilePhotoSelected"
      accept="image/*"
      style="display: none"
      ref="fileInput"
    />
    <div 
      class="upload-area" 
      @click="openFileInput"
      @dragover.prevent
      @drop.prevent="onDrop"
    >
      <q-img
        :src="previewSrc ?? ''"
        style="width: 100px; height: 100px; object-fit: cover;"
        class="rounded-borders"
      >
        <div v-if="!localProfilePhoto" class="absolute-bottom text-subtitle2 text-center bg-transparent">
          Upload Profile Picture
        </div>
      </q-img>
      <q-icon v-if="!localProfilePhoto" name="add" size="20px" class="absolute-top-right q-mr-xs q-mt-xs" color="grey-7" />
    </div>
    <div class="text-caption q-mt-sm">
      <q-icon name="info" size="16px" color="grey-7" />
      Profile Picture Information
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';

const props = defineProps<{
  modelValue: File | null
}>();

const emit = defineEmits(['update:modelValue']);

const localProfilePhoto = ref<File | null>(null);
const previewSrc = ref<string | null>(null);
const fileInput = ref<HTMLElement | null>(null);

const onProfilePhotoSelected = (file: File | null) => {
  if (file) {
    localProfilePhoto.value = file;
    emit('update:modelValue', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      previewSrc.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  } else {
    localProfilePhoto.value = null;
    previewSrc.value = null;
    emit('update:modelValue', null);
  }
};

const openFileInput = () => {
  const input = fileInput.value as HTMLElement | null;
  if (input && 'pickFiles' in input) {
    (input as any).pickFiles();
  }
};

const onDrop = (e: DragEvent) => {
  const files = e.dataTransfer?.files;
  if (files && files.length > 0) {
    onProfilePhotoSelected(files[0]);
  }
};

watch(() => props.modelValue, (newValue) => {
  if (newValue !== localProfilePhoto.value) {
    onProfilePhotoSelected(newValue);
  }
});

// Default avatar gösterimi için
const defaultAvatarSrc = '/img/default-avatar.png';
const computedPreviewSrc = computed(() => previewSrc.value || defaultAvatarSrc);
</script>

<style scoped>
.profile-upload {
  width: 100px;
  margin: 0 auto;
}

.upload-area {
  position: relative;
  cursor: pointer;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 4px;
  overflow: hidden;
}

.upload-area:hover {
  border-color: #1976D2;
}
</style>