<!-- // src/components/FilterSideBar.vue  -->
<template>
  <q-list class="rounded-borders">
    <q-expansion-item
      class="expand-item text-left"
      expand-separator
      label="Categories"
    >
      <q-card>
        <q-card-section style="padding-left: 0 !important">
          <div class="row items-center">
            <div class="col-6">
              <q-checkbox v-model="right" label="Psychologist" />
            </div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>

          <div class="row items-center">
            <div class="col-6"><q-checkbox v-model="right" label="Yoga" /></div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>

          <div class="row items-center">
            <div class="col-6">
              <q-checkbox v-model="right" label="Nutritionist" />
            </div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>

          <div class="row items-center">
            <div class="col-6">
              <q-checkbox v-model="right" label="Psychologist" />
            </div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item
      class="expand-item text-left"
      expand-separator
      label="Language"
    >
      <q-card>
        <q-card-section style="padding-left: 0 !important">
          <div class="row items-center">
            <div class="col-6">
              <q-checkbox v-model="right" label="English" />
            </div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>
          <div class="row items-center">
            <div class="col-6">
              <q-checkbox v-model="right" label="Turkish" />
            </div>
            <div class="col-6 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item
      class="expand-item text-left"
      expand-separator
      label="Availability"
    >
      <q-card>
        <q-card-section style="padding-left: 0 !important">
          <div class="row items-center">
            <div class="col-10">
              <q-checkbox v-model="right" label="Online Now" />
            </div>
            <div class="col-2 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>
          <div class="row items-center">
            <div class="col-10">
              <q-checkbox v-model="right" label="Available in next 2 hours" />
            </div>
            <div class="col-2 row justify-end">
              <q-chip
                style="
                  width: 32px;
                  height: 18px;
                  flex-shrink: 0;
                  background: var(--greyscale-100, #f1f5f9);
                  border-radius: 6px;
                "
                ><span class="consultant-chip">21</span></q-chip
              >
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item
      class="expand-item text-left"
      expand-separator
      label="Price Range"
    >
      <q-card>
        <q-card-section style="padding-left: 0 !important">
          <div class="row items-center">
            <q-range v-model="standard" :min="0" :max="50" />
          </div>
          <div class="row q-col-gutter-xl items-center" style="margin-top: 2px;">
            <div class="col-6">
              <q-input outlined v-model="text" label="Min" />
            </div>
            <div class="col-6">
              <q-input outlined v-model="text" label="Max" />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>
<script setup lang="ts">
import { ref } from "vue";

const right = ref(false);
const standard = ref({
  min: 10,
  max: 35,
});
const text = ref("");
</script>
<style>
.expand-item .q-item__label {
  color: var(--greyscale-900, #0f172a) !important;
  font-family: Red Hat Display !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important; /* 27px */
}
.expand-item .q-icon {
  color: var(--greyscale-900, #0f172a) !important;
}

.expand-item .q-checkbox__bg {
  border: 2px solid #e2e8f0 !important;
}

.expand-item .q-checkbox__label {
  color: var(--greyscale-700, #334155) !important;
  font-family: Red Hat Display !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 100% !important; /* 18px */
}

.consultant-chip {
  color: var(--greyscale-400, #94a3b8);
  font-family: Red Hat Display;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
}
</style>
