import script from "./CountrySelect.vue?vue&type=script&lang=ts&setup=true"
export * from "./CountrySelect.vue?vue&type=script&lang=ts&setup=true"

import "./CountrySelect.vue?vue&type=style&index=0&id=c5482ba4&lang=css"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QSeparator,QInput,QIcon,QField});
