import script from "./PublicLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./PublicLayout.vue?vue&type=script&setup=true&lang=ts"

import "./PublicLayout.vue?vue&type=style&index=0&id=3db5b797&lang=css"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QBtn});
