import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ac2a3102"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "blog-post-list" }
const _hoisted_2 = {
  key: 0,
  class: "main-post"
}
const _hoisted_3 = { class: "related-posts" }
const _hoisted_4 = {
  key: 1,
  class: "load-more"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlogListingItem = _resolveComponent("BlogListingItem")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.posts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BlogListingItem, {
            id: _ctx.posts[0]._id,
            title: _ctx.posts[0].title,
            text: _ctx.stripHtml(_ctx.posts[0].content).substring(0, 600) + '...',
            coverImageUrl: _ctx.posts[0].image,
            userImage: _ctx.posts[0].consultant.avatar,
            userName: _ctx.posts[0].consultant.name + ' ' + _ctx.posts[0].consultant.surname,
            date: new Date(_ctx.posts[0].createdAt).toLocaleDateString(),
            tag: _ctx.posts[0].category?.name,
            isLarge: true
          }, null, 8, ["id", "title", "text", "coverImageUrl", "userImage", "userName", "date", "tag"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts.slice(1), (post) => {
        return (_openBlock(), _createElementBlock("div", {
          key: post._id,
          class: "related-post"
        }, [
          _createVNode(_component_BlogListingItem, {
            id: post._id,
            title: post.title,
            text: _ctx.stripHtml(post.content).substring(0, 150) + '...',
            coverImageUrl: post.image,
            userImage: post.consultant.avatar,
            userName: post.consultant.name + ' ' + post.consultant.surname,
            date: new Date(post.createdAt).toLocaleDateString(),
            tag: post.category?.name
          }, null, 8, ["id", "title", "text", "coverImageUrl", "userImage", "userName", "date", "tag"])
        ]))
      }), 128))
    ]),
    (_ctx.hasMorePosts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_q_btn, {
            class: "text-capitalize",
            outline: "",
            style: {"color":"#0069ff","border-radius":"16px","padding":"8px","width":"236px","height":"48px"},
            label: "Load More Blogs",
            icon: "img:/img/icons/plus.svg",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loadMore')))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}