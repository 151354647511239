<template>
    <q-card class="create-comment-card">
      <q-card-section>
        <q-input
          v-model="comment"
          type="textarea"
          label="Type your comment"
          autogrow
          :rules="[val => val.length <= 300 || 'You can only enter a maximum of 300 characters']"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="Send Comment" color="primary" @click="sendComment" :disable="!comment.trim()" style="border-radius: 10px;,background-color: #0069ff"/>
      </q-card-actions>
    </q-card>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import { useQuasar } from 'quasar';
  import { useRouter } from 'vue-router';
  import { useUserStore } from '@/store/user';

  const $q = useQuasar();
  const router = useRouter();
  const userStore = useUserStore();
  const comment = ref('');

  const props = defineProps<{
    blogId: string;
  }>();

  const emit = defineEmits(['commentCreated']);

  const sendComment = () => {
    if (!userStore.isAuthenticated) {
      $q.dialog({
        title: 'Login Required',
        message: 'You need to be logged in to post a comment. Would you like to log in now?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        router.push({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } });
      })
    } else {
      submitComment();
    }
  }

  async function submitComment() {
    try {
      const response = await fetch('/api/blog-comment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          comment: comment.value,
          blogId: props.blogId,
        }),
      });

      if (!response.ok) {
        throw new Error('Comment not sent');
      }

      const result = await response.json();
      emit('commentCreated', result);
      comment.value = '';
      $q.notify({
        color: 'positive',
        message: 'Your comment has been sent successfully',
        icon: 'check',
      });
    } catch (error) {
      $q.notify({
        color: 'negative',
        message: 'An error occurred while sending the comment',
        icon: 'error',
      });
    }
  }
  </script>
  
  <style scoped>
  .create-comment-card {
    margin-bottom: 20px;
  }
  </style>