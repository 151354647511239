<!-- // src/components/InhaleSection.vue  -->
<template>
  <div
    style="
      background-color: #1e293b;
      background-image: url('/img/icons/Patern.png');
      background-repeat: no-repeat;
    "
  >
    <div class="container">
      <div class="row inhale-row q-col-gutter-x-xl">
        <div class="col-md-6 col-sm-12 column">
          <PublicColoredLineHeader color="#FF6155" text="Breathing Exercise" />
          <h3
            class="text-start prg-1"
            style="
              font-size: 52px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%;
              color: #f9f9f9;
              text-align: start;
              margin-bottom: 24px !important;
            "
          >
            Minimize Your Stress Get Your 5% Off with Breathing Exercise
          </h3>
          <p
            style="
              color: var(--greyscale-100, #f1f5f9);
              font-family: Red Hat Display;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              text-align: left;
            "
          >
            Studies show that practicing 4-7-8 breathing exercises for just a
            few minutes a day can lower blood pressure, relieve tension, and
            help you feel calmer by activating your parasympathetic nervous
            system.
          </p>
          <div
            style="
              color: var(--greyscale-100, #f1f5f9);
              font-size: 18px;
              font-style: italic;
              font-weight: 400;
              line-height: 150%;
            "
          >
            <p style="text-align: left">
              Before you get started, keep these tips in mind
            </p>
            <ul style="text-align: left">
              <li>
                Sit comfortably with your back straight. Place the tip of your
                tongue on the ridge behind your upper teeth
              </li>
              <li>
                The animation counts you through inhaling quietly through your
                nose for a count of 4 seconds. Feel your lungs expand.
              </li>
              <li>Hold your breath for a mental count of 7 seconds.</li>
              <li>
                Exhale through your mouth around your tongue for 8 seconds,
                making a whooshing sound.
              </li>
              <li>
                Repeat this cycle a second time, inhaling for 4 seconds, holding
                for 7 seconds, and exhaling for 8 seconds.
              </li>
              <li>This completes one round of 4-7-8 breathing.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <q-card flat class="q-pa-lg inhale-card">
            <img src="/img/icons/inhalepicture.svg" />
            <div class="column items-center justify-center q-mt-md">
              <div>
                <h3 class="inhale-title">{{ currentStep }}</h3>
              </div>
              <div class="timer">{{ currentSeconds }}s</div>
              <div class="rounds">{{ cycleCount === 2 ? 'Congratulations!' : 'Round ' + (cycleCount + 1) }}</div>
              <q-slider
                readonly
                v-model="progress"
                :min="0"
                :max="1"
                style="margin-bottom: 40px"
              />
              <q-btn
                class="hold-button"
                :label="buttonLabel"
                color="primary"
                @mousedown="startProgressBar"
                @mouseup="resetProgressBar"
                @mouseleave="resetProgressBar"
                @touchstart="startProgressBar"
                @touchend="resetProgressBar"
              />
              <q-btn
                v-if="cycleCount > 0 || stepIndex > 0"
                class="reset-button"
                label="Restart Exercise"
                color="secondary"
                @click="restartExercise"
                style="margin-top: 10px; width: 150px; height: 40px;"
              />
            </div>
          </q-card>
        </div>
      </div>
    </div>
    <q-dialog v-model="showConfetti" @hide="resetAfterConfetti">
      <q-card>
        <q-card-section>
          <div class="text-h6">Congratulations!</div>
        </q-card-section>
        <q-card-section>
          <div>You have completed the breathing exercise.</div>
          <div>Your %5 discount code is <b>BREATH10</b></div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Close" color="primary" @click="closeConfetti" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <canvas ref="confetti" class="confetti-canvas"></canvas>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import PublicColoredLineHeader from "./PublicColoredLineHeader.vue";
import { useRoute } from 'vue-router';

const progress = ref(0);
const showConfetti = ref(false);
let progressBarInterval: any;
let countdownInterval: any;

const steps = ["INHALE", "HOLD", "EXHALE"];
const currentStep = ref(steps[0]);
const stepDurations = [4000, 7000, 8000]; // Milliseconds for each step
const currentSeconds = ref(stepDurations[0] / 1000);
let stepIndex = 0;
let cycleCount = 0;

let confetti: any = null;

const loadConfetti = async () => {
  if (!confetti) {
    const module = await import('canvas-confetti');
    confetti = module.default;
  }
};

const route = useRoute();

watch(() => route.path, () => {
  if (confetti && confetti.reset) {
    confetti.reset();
  }
});

const buttonLabel = computed(() => {
  switch (currentStep.value) {
    case "INHALE":
      return "Start Inhaling";
    case "HOLD":
      return "Hold Your Breath";
    case "EXHALE":
      return "Start Exhaling";
    default:
      return "Hold Button";
  }
});

const startProgressBar = () => {
  resetProgressBar();
  progress.value = 0;
  currentSeconds.value = stepDurations[stepIndex] / 1000;
  progressBarInterval = setInterval(() => {
    if (progress.value < 1) {
      progress.value += 0.01;
    } else {
      resetProgressBar();
      if (stepIndex === steps.length - 1) {
        cycleCount++;
        if (cycleCount === 2) {
          showConfetti.value = true;
          fireConfetti();
        } else {
          stepIndex = 0;
          currentStep.value = steps[stepIndex];
        }
      } else {
        stepIndex = (stepIndex + 1) % steps.length;
        currentStep.value = steps[stepIndex];
      }
      if (cycleCount < 2) {
        startProgressBar();
      }
    }
  }, stepDurations[stepIndex] / 100);
  countdownInterval = setInterval(() => {
    if (currentSeconds.value > 0) {
      currentSeconds.value -= 1;
    }
  }, 1000);
};

const resetProgressBar = () => {
  clearInterval(progressBarInterval);
  clearInterval(countdownInterval);
  progress.value = 0;
  currentSeconds.value = stepDurations[stepIndex] / 1000;
};

const restartExercise = () => {
  cycleCount = 0;
  stepIndex = 0;
  currentStep.value = steps[stepIndex];
  resetProgressBar();
};

const resetAfterConfetti = () => {
  cycleCount = 0;
  resetProgressBar();
};

const closeConfetti = () => {
  showConfetti.value = false;
  restartExercise();
};

const fireConfetti = async () => {
  await loadConfetti();
  if (confetti) {
    const duration = 5 * 1000;
    const end = Date.now() + duration;

    const frame = () => {
      confetti({
        particleCount: 5,
        angle: 60,
        spread: 55,
        origin: { x: 0 }
      });
      confetti({
        particleCount: 5,
        angle: 120,
        spread: 55,
        origin: { x: 1 }
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    };

    frame();
  }
};

onMounted(() => {
  currentStep.value = steps[0];
});

onUnmounted(() => {
  resetProgressBar();
  if (confetti && confetti.reset) {
    confetti.reset();
  }
});
</script>

<style scoped>
.inhale-card {
  border-radius: 16px !important;
  border: 1px solid #334155 !important;
  background-color: transparent !important;
}
.inhale-card .q-slider__track,
.q-slider__thumb {
  color: #1ae364 !important;
}

.inhale-card .q-slider__track {
  background-color: #fff !important;
}

.inhale-title {
  color: #fff;
  text-align: center;
  font-family: Red Hat Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  margin-bottom: 8px; /* Saniye göstergesi için boşluk */
}

.timer {
  color: #fff;
  text-align: center;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  margin-bottom: 10px;
}

.rounds {
  color: #fff;
  text-align: center;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  margin-bottom: 20px;
}

.hold-button {
  background-color: #0069ff !important; /* Book Appointment butonu rengi */
  border-radius: 16px !important;
  padding: 8px !important;
  width: 198px !important;
  height: 56px !important;
  gap: 10px !important;
}
.hold-button .q-btn__content {
  color: #ffffff !important; /* Book Appointment butonu yazı rengi */
  text-transform: capitalize;
  /* Body Large (Medium) */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}

.reset-button {
  background-color: #ff5555 !important;
  border-radius: 16px !important;
  padding: 8px !important;
  width: 150px !important;
  height: 40px !important;
  gap: 10px !important;
}
.reset-button .q-btn__content {
  color: #ffffff !important;
  text-transform: capitalize;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.inhale-row {
  padding: 97px 0px !important;
}

.confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}
</style>
