<!-- // src/components/ConsultantDetailsHeader.vue -->
<template>
  <div class="consultant-search-header">
    <div class="container">
      <div class="row q-col-gutter-x-sm screen--sm visible">
        <div class="col-md-3 col-xs-12">
          <q-img
            class="consultant-img"
            src="https://picsum.photos/500/300"
            spinner-color="white"
          />
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="row justify-between">
            <div class="column q-gutter-y-md">
              <span class="flex q-gutter-xs align-center">
                <img src="/img/icons/publicOnlineIcon.svg" alt=""  class="online-now-btn">
                <span class="online-now">Online Now</span>
              </span>
              <div class="row justify-between">
                <div class="screen-lg-visible2">
                  <q-img src="https://picsum.photos/500/300" class="screen-lg-visible "/>
                </div>
                <div class="consultant-name">Dr. Celine Dion</div>
              </div>
            </div>

            <span class="flex q-gutter-x-sm align-center">
              <img src="/img/icons/sharelight.svg" alt="" />
              <span class="share-text">Share</span>
            </span>
          </div>
          <div class="row items-center q-mt-sm q-gutter-x-sm">
            <span class="social-life-coach">Social Life Coach</span>
            <span class="rating">4.7</span>
            <span class="rating-star">
              <q-img
                class="rating-icon"
                src="/img/icons/star-solid-gold.svg"
              ></q-img>
            </span>
            <div>
              <span class="consultant-review">(32 reviews)</span>
            </div>
          </div>
          <q-separator class="q-mt-md separator" />
          <div class="row q-mt-md">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row q-gutter-lg items-center">
                <div>
                  <img src="/img/icons/free-icon.svg" alt="" />
                </div>
                <div class="text-left">
                  <p class="text-body2">Languages:</p>
                  <p class="text-body1">English, Turkish</p>
                </div>
                <div class="text-left">
                  <p class="text-body2">Experience:</p>
                  <p class="text-body1">7+ Years</p>
                </div>
                <div class="text-left">
                  <p class="text-body2">Total Patients:</p>
                  <p class="text-body1">102</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 row justify-end items-center">
              <q-btn
                class="watch-video-btn text-capitalize q-mx-sm"
                label="Watch"
                icon="play_circle_outlined"
                outline
              />
              <q-btn
                class="book-session-btn text-capitalize q-mx-sm"
                label="Book Session Now"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.consultant-search-header {
  padding: 3%;
}

.container {
  margin-top: 5%;
}

.consultant-img {
  border-radius: 16px;
  border: 1px solid #fff;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.align-center {
  align-items: center;
}

.online-now {
  color: var(--additional-emerald, #34d399);
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.consultant-name {
  color: #f9f9f9;
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  margin: 0; /* Varsayılan olarak margin'i sıfırla */
}
@media (max-width: 768px) {
  .consultant-name {
    margin: 2%; /* Küçük ekranlarda uygulanacak margin değeri */
  }
}

.share-text {
  text-align: center;
  color: var(--greyscale-300, #cbd5e1);
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
}

.social-life-coach {
  color: #cbd5e1;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
}

.rating {
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  color: #cbd5e1;
}

.rating-icon {
  width: 16px;
  height: 16px;
}

.consultant-review {
  color: #cbd5e1;
}

.separator {
  background-color: #cbd5e1;
}

.text-body2 {
  color: #cbd5e1;
}

.text-body1 {
  color: #f8fafc;
}

.book-session-btn {
  background: #34d399;
  color: white;
  border-radius: 16px;
  font-size: 14px;
  padding: 8px 15px;
  margin-bottom: 2%;
}

.watch-video-btn {
  color: #e6f0ec;
  border-radius: 16px;
  font-size: 14px;
  padding: 8px 15px;
  margin-left: 8px;
  margin-bottom: 2%;
}



@media (max-width: 768px) {
  .consultant-img {
    display: none;
  }

  .consultant-search-header .container {
    margin-top: 25%;
  }

  .consultant-name {
    font-size: 24px;
  }

  .social-life-coach {
    font-size: 16px;
  }

  .rating {
    font-size: 14px;
  }

  .book-session-btn {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}
.online-now {
  display: none;
}
.online-now-btn {
  display: none;
}
.screen-lg-visible {
  display: block;
  margin: 3%;
  padding: 32px;
  margin-right: 50%;
  background-color: #f8f9fa;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
@media (min-width: 769px) {
  .screen-lg-visible {
    display: none;
  }
  
}
</style>

