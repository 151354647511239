<template>
  <q-dialog v-model="localIsOpen" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Verify Your Contact Information</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="q-mb-md">
          <div class="text-subtitle1">Email Verification</div>
          <q-input v-model="email" label="Email" readonly dense class="q-mb-sm" :placeholder="props.email">
            <template v-slot:append>
              <q-btn :loading="isEmailCodeSending" label="Send Code" color="primary" flat @click="sendEmailCode" />
            </template>
          </q-input>
          <q-input 
            v-if="isEmailCodeSent" 
            v-model="emailCode" 
            label="Verification Code" 
            dense 
            class="q-mb-sm"
            maxlength="6"
            @input="emailCode = emailCode.trim()"
          >
            <template v-slot:append>
              <q-btn :loading="isEmailVerifying" label="Verify" color="primary" flat @click="verifyEmail" />
            </template>
          </q-input>
        </div>

        <div>
          <div class="text-subtitle1">Phone Verification</div>
          <q-input v-model="phone" label="Phone" readonly dense class="q-mb-sm" :placeholder="props.phone">
            <template v-slot:append>
              <q-btn :loading="isPhoneCodeSending" label="Send Code" color="primary" flat @click="sendPhoneCode" />
            </template>
          </q-input>
          <q-input 
            v-if="isPhoneCodeSent" 
            v-model="phoneCode" 
            label="Verification Code" 
            dense 
            class="q-mb-sm"
            maxlength="6"
            @input="phoneCode = phoneCode.trim()"
          >
            <template v-slot:append>
              <q-btn :loading="isPhoneVerifying" label="Verify" color="primary" flat @click="verifyPhone" />
            </template>
          </q-input>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Close" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from 'vue';
import { useQuasar } from 'quasar';
import { authService } from '@/services/auth.service';

const props = withDefaults(defineProps<{
  isOpen: boolean;
  email: string;
  phone: string;
  userId: string;
}>(), {
  phone: ''
});

const emit = defineEmits(['update:isOpen', 'verificationComplete']);
const localIsOpen = ref(props.isOpen);
const $q = useQuasar();

const email = ref(props.email);
const phone = ref(props.phone);
const emailCode = ref('');
const phoneCode = ref('');

const isEmailCodeSent = ref(false);
const isPhoneCodeSent = ref(false);
const isEmailCodeSending = ref(false);
const isPhoneCodeSending = ref(false);
const isEmailVerifying = ref(false);
const isPhoneVerifying = ref(false);

const sendEmailCode = async () => {
  isEmailCodeSending.value = true;
  try {
    await authService.sendVerificationEmail(props.userId);
    isEmailCodeSent.value = true;
    $q.notify({ type: 'positive', message: 'Verification code sent to your email' });
  } catch (error) {
    $q.notify({ type: 'negative', message: 'Failed to send email verification code' });
  } finally {
    isEmailCodeSending.value = false;
  }
};

const verifyEmail = async () => {
  isEmailVerifying.value = true;
  try {
    await authService.verifyEmail(props.userId, emailCode.value.trim());
    $q.notify({ type: 'positive', message: 'Email verified successfully' });
    emit('verificationComplete', 'email');
  } catch (error) {
    $q.notify({ type: 'negative', message: 'Failed to verify email' });
  } finally {
    isEmailVerifying.value = false;
  }
};

const sendPhoneCode = async () => {
  isPhoneCodeSending.value = true;
  try {
    await authService.sendPhoneOtp(props.phone);
    isPhoneCodeSent.value = true;
    $q.notify({ type: 'positive', message: 'Verification code sent to your phone' });
  } catch (error) {
    $q.notify({ type: 'negative', message: 'Failed to send phone verification code' });
  } finally {
    isPhoneCodeSending.value = false;
  }
};

const verifyPhone = async () => {
  isPhoneVerifying.value = true;
  try {
    await authService.verifyPhoneOtp(props.phone, phoneCode.value.trim());
    emit('verificationComplete', 'phone');
  } catch (error) {
    $q.notify({ type: 'negative', message: 'Failed to verify phone' });
  } finally {
    isPhoneVerifying.value = false;
  }
};

watch(() => props.isOpen, (newValue) => {
  localIsOpen.value = newValue;
});

watch(localIsOpen, (newValue) => {
  emit('update:isOpen', newValue);
});
</script>