// src/utils/axios.ts
import axios from "axios";
import { API_BASE_URL } from "./config";
import { useUserStore } from "@/store/user";
import router from "@/router";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // CORS ile ilgili çerez gönderimi için
});

// API Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Token bazlı yetkilendirme kullanmıyoruz artık, çünkü çerez tabanlı yetkilendirme kullanıyoruz
    // Ancak gerekirse ek header'lar ekleyebilirsiniz
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Oturum süresi dolmuş veya geçersiz oturum
        const userStore = useUserStore();
        await userStore.logout(); // Store'daki oturum bilgilerini temizle
        router.push('/login'); // Login sayfasına yönlendir
      }
      // Diğer hata durumları için işlemler ekleyebilirsiniz
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;