import { defineStore } from "pinia";
import { authService,RegisterUserDto  } from "@/services/auth.service";

interface IProfile {
  _id: string;
  email: string;
  name: string;
  surname: string;
  username?: string;
  role: string;
  isProfileCompleted: boolean;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

interface IState {
  profile: IProfile | null;
  isAuthenticated: boolean;
  errorMessage: string | null;
  isProfileCompleted: boolean;
}

export const useUserStore = defineStore("user", {
  state: (): IState => ({
    profile: null,
    isAuthenticated: false,
    errorMessage: null as string | null,
    isProfileCompleted: false,
  }),
  actions: {
    async login(email: string, password: string) {
      try {
        const userData = await authService.login(email, password);
        this.profile = userData;
        this.isAuthenticated = true;
        this.isProfileCompleted = userData.isProfileCompleted;
        this.errorMessage = null;
      } catch (error: unknown) {
        if (error instanceof Error) {
          this.errorMessage = error.message || "Something went wrong. Please try again.";
          console.error("Giriş hatası:", error);
        } else {
          this.errorMessage = "Unknown error occurred.Please try again.";
          console.error("Bilinmeyen giriş hatası:", error);
        }
      }
    },
    async logout() {
      try {
        await authService.logout();
        this.profile = null;
        this.isAuthenticated = false;
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
    async fetchCurrentUser() {
      try {
        const user = await authService.getCurrentUser();
        if (user) {
          this.profile = user;
          this.isAuthenticated = true;
        } else {
          this.profile = null;
          this.isAuthenticated = false;
        }
      } catch (error) {
        console.error("Fetch current user error:", error);
        this.profile = null;
        this.isAuthenticated = false;
      }
    },
    removeErrorMessage() {
      this.errorMessage = null;
    },
    async register(userData: FormData) {
      try {
        const response = await authService.register(userData);
        this.profile = response.user;
        this.isAuthenticated = true;
        this.errorMessage = null;
        return response;
      } catch (error: unknown) {
        if (error instanceof Error) {
          this.errorMessage = error.message || "Register failed. Please try again.";
          console.error("Kayıt hatası:", error);
        } else {
          this.errorMessage = "Unknown error occurred.Please try again.";
          console.error("Bilinmeyen kayıt hatası:", error);
        }
        throw error;
      }
    },
    async forgotPassword(email: string) {
      try {
        await authService.forgotPassword(email);
        this.errorMessage = null;
        return true;
      } catch (error) {
        this.errorMessage = "Failed to send password reset email. Please try again.";
        console.error("Forgot password error:", error);
        return false;
      }
    },
    async validateResetPasswordToken(token: string) {
      try {
        const response=await authService.validateResetToken(token);
        console.log(response);
        console.log(typeof response);
        this.errorMessage = null;
        return true;
      } catch (error) {
        this.errorMessage = "Your password reset token is invalid or expired.";
        console.error("Code validation error:", error);
        return false;
      }
    },

    async resetPassword(token: string, newPassword: string) {
      try {
        await authService.resetPassword(token, newPassword);
        this.errorMessage = null;
        return true;
      } catch (error) {
        this.errorMessage = "Failed to reset password. Please try again.";
        console.error("Reset password error:", error);
        return false;
      }
    },
    clearErrorMessage() {
      this.errorMessage = null;
    },
  },
  persist: true,
});