// src/store/discountCoupon/index.ts

import axiosInstance from "@/utils/axios";
import { defineStore } from "pinia";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { reactive } from "vue";

interface ICoupon {
    _id:string
    code: string
    limit: number,
    isUnlimited: boolean,
    remaining: number,
    startDate:Date,
    endDate: Date,
    discountRate: number,
    isActive: boolean
  }

interface IState {
    coupons: ICoupon[],
    coupon: ICoupon,
    errorMessage: null | string;
}

interface CouponCreateDto {
  code: string,
  limit: number,
  isUnlimited: boolean,
  discountRate: number,
  isActive: boolean,
  startDate: string,
  endDate: string
}

interface IIdBody {
    id: string
}


export const useDiscountCoupon = defineStore('discountCoupon', () => {
    const $q = useQuasar()

    const state = reactive<IState>({
        coupons: [] as ICoupon[],
        coupon:{} as ICoupon,
        errorMessage: null
    })

    const getDiscountCoupons = async () => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            const couponResponse = await axiosInstance.get("/promo-code");
            state.coupons = couponResponse.data
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while coupon list.";
            $q.loading.hide();
        }
    }

    const createCoupon = async (dto: CouponCreateDto) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
            await axiosInstance.post("/admin/promo-code/create", dto);
            $q.loading.hide();
        } catch (error) {
            state.errorMessage = "Error occured while coupon creating.";
            $q.notify({
                message:'Coupon name already exist.',
                position:'top-right',
                timeout: 2000
              })
            $q.loading.hide();
        }
    }

    const deleteCoupon = async ({id}:IIdBody) => {
        $q.loading.show();
        state.errorMessage = null;
        try {
          await axiosInstance.delete(`/admin/promo-code/${id}`);
          state.coupons = state.coupons.filter((e) => e._id != id);
          $q.loading.hide();
        } catch (error) {
          state.errorMessage = "Error occured while deleting coupon.";
          $q.loading.hide();
        }
    }

    return {
        state,
        getDiscountCoupons,
        createCoupon,
        deleteCoupon
    }
})