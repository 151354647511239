// src/router/index.ts

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/Public/AboutView.vue";
import FaqView from "../views/Public/FaqView.vue";
import ConsultantSearchView from "../views/Public/ConsultantSearch.vue";
import SubscriptionView from "../views/Public/SubscriptionView.vue";
import ConsultantDetails from "../views/Public/ConsultantDetails.vue";
import BlogDetail from "../views/Public/BlogDetail.vue";
import Blog from "../views/Public/Blog.vue";
import LoginView from "@/views/Public/LoginView.vue";
import RegisterView from "@/views/Public/RegisterView.vue";
import ForgotPasswordView from "../views/Public/ForgotPasswordView.vue";

import {PublicLayout } from "../components";
import ConsultantApplication from "@/views/Public/ConsultantApplication.vue";
import EmergencyResources from "@/views/Public/EmergencyResources.vue";
import ApplicationSubmitted from "@/views/Public/ApplicationSubmitted.vue";

const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { layout: PublicLayout },
  },
  {
    path: "/consultant-application",
    name: "Test",
    component: ConsultantApplication,
    // meta: { layout: PublicLayout },
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
    meta: { layout: PublicLayout },
  },
  {
    path: "/faq",
    name: "Faq",
    component: FaqView,
    meta: { layout: PublicLayout },
  },
  {
    path: "/consultants",
    name: "Consultants",
    component: ConsultantSearchView,
    meta: { layout: PublicLayout },
  },
  {
    path: "/consultants/:id",
    name: "Consultant Details",
    component: ConsultantDetails,
    meta: { layout: PublicLayout },
  },
  {
    path: "/subscriptions",
    name: "Subscription",
    component: SubscriptionView,
    meta: { layout: PublicLayout },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/blog/:id",
    name: "BlogDetail",
    component: BlogDetail,
    meta: { layout: PublicLayout },
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blog,
    meta: { layout: PublicLayout },
  },
  {
    path: "/emergency-resources",
    name: "Emergency Resources",
    component: EmergencyResources,
    meta: { layout: PublicLayout },
  },
  {
    path: '/application-submitted/:email',
    name: 'ApplicationSubmitted',
    component: ApplicationSubmitted,
  },
];

const routes = [...publicRoutes,];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
