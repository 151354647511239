<template>
  <div class="q-pa-md">
    <div class="row q-gutter-x-sm justify-center">
      <q-input
        outlined
        v-for="i in length"
        v-model="fieldValues[i - 1]"
        v-bind="$attrs"
        @keyup="onKeyUp($event, i - 1)"
        @update:model-value="onUpdate($event, i - 1)"
        :key="i"
        :ref="el => updateFieldRef(el, i - 1)"
        maxlength="1"
        input-class="text-center"
        style="width: 6ch"
        color="primary">
      </q-input>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onBeforeUpdate } from 'vue';
import { Notify } from 'quasar'; // Assuming Notify is from Quasar

export default defineComponent({
  name: 'OtpContent',
  emits: ['update:modelValue'],
  props: {
    inputLength: {
      type: Number,
      default: 6,
    },
  },
  setup(props, { emit }) {
    const length = computed(() => props.inputLength);
    const fields = ref<any[]>([]);
    const fieldValues = ref<any[]>([]);

    const composite = computed(() => {
      const nonNullFields = fieldValues.value.filter((value) => value);
      if (length.value !== nonNullFields.length) {
        return "";
      }
      return nonNullFields.join("");
    });

    watch(composite, () => {
      if (composite.value) {
        emit("update:modelValue", composite.value);
        Notify.create({
          message: `New input: ${composite.value}`,
          type: "positive"
        });

        console.log(composite.value);
      }
    });

    onBeforeUpdate(() => {
      fields.value = [];
    });

    const updateFieldRef = (element: any, index: number) => {
      if (element) {
        fields.value[index] = element;
      }
    };

    const focus = (index: number) => {
      if (index >= 0) {
        if (index < length.value) {
          fields.value[index].select();
        } else {
          if (composite.value) {
            fields.value[index - 1].blur();
          }
        }
      }
    };

    const onUpdate = (value: any, index: number) => {
      if (value) {
        focus(index + 1);
      }
    };

    const onKeyUp = (evnt: KeyboardEvent, index: number) => {
      const key = evnt.key;

      if (["Tab", "Shift", "Meta", "Control", "Alt"].includes(key)) {
        return;
      }

      if (["Delete"].includes(key)) {
        return;
      }

      if (key === "ArrowLeft" || key === "Backspace") {
        focus(index - 1);
      } else if (key === "ArrowRight") {
        focus(index + 1);
      }
    };

    return {
      composite,
      fields,
      fieldValues,
      focus,
      length,
      onKeyUp,
      onUpdate,
      updateFieldRef
    };
  }
});
</script>