<!-- // src/views/HomeView.vue -->
<template>
  <div class="home">
    <HomePageHeader></HomePageHeader>
    <section class="container q-mt-xl row justify-left">
      <div class="col-md-6 column">
        <PublicColoredLineHeader color="#FF6155" text="Problem Solving" />
        <p
          class="q-mt-md q-mb-none prg-1"
          style="
            font-size: 52px;
            font-weight: 700;
            line-height: 57px;
            text-align: start;
            cursor: pointer;
            color: #0f172a;
          "
        >
          What are our core competencies?
        </p>
      </div>
      <div class="col-md-6 column justify-end">
        <span class="text-left">
          One-stop solution with holistic experts for every challenge. Find your
          path to balance without medication.
        </span>
      </div>
    </section>
    <section class="marquee-wrapper q-mt-lg q-gutter-lg">
      <div class="marquee-content">
        <div v-for="item in expertiseDummyData" :key="item" class="marquee-item">
          <HomeExpertiseCard :text="item" />
        </div>
        <div v-for="item in expertiseDummyData" :key="'clone-' + item" class="marquee-item">
          <HomeExpertiseCard :text="item" />
        </div>
      </div>
    </section>

    <section class="container q-mt-xl">
      <div class="row">
        <PublicColoredLineHeader color="#0069FF" text="Popular Consultant" />
      </div>
      <div class="row q-mt-md q-mb-xl">
        <div class="col-md-6 text-left">
          <div class="text-h3 text-weight-bold" style="color: #0f172a">
            15-Minute Free
          </div>
          <div class="text-h3 text-weight-bold" style="color: #0069ff">
            First Meeting!
          </div>
        </div>
        <div class="col-md-6 column justify-end">
          <div class="text-body1 text-left">
            Got some doubts? Dive into "Minimum Stress"! Meet your chosen
            expert, hear their thoughts, and get a feel for their approach.
            We're all about giving you a free 15-minute consultation to start
            things right and sync up your schedules. Give it a go!
          </div>
        </div>
      </div>
      <div class="q-pt-lg">
        <PopularConsultantSection />
      </div>
    </section>
    <section class="q-mt-xl">
      <InhaleSection />
    </section>

    <section class="container q-mt-xl">
      <div class="row q-col-gutter-xl">
        <div class="col-md-6 prg-1">
          <HomeVerticalBlogContent
            title="Prioritize Your Well-Being"
            text="Get ready to unlock your full potential for health and wellness with our expert guidance and advice!  This blog is your ultimate resource for the latest trends and developments in holistic health"
          />
        </div>
        <div class="col-md-6">
          <HomeSmallBlogContent
            name="Dr. Muhittin Topalak"
            date="21.07.2023"
            tag="Health"
            title="8 Rules of Kito diet for adult womens"
            text="The keto diet could impact stress and  hormones that influence your menstrual cycle and fertility For some women  your menstrual cycle and fertility For some..."
          />
          <HomeSmallBlogContent
            name="Dr. Muhittin Topalak"
            date="21.07.2023"
            tag="Health"
            title="8 Rules of Kito diet for adult womens"
            text="The keto diet could impact stress and  hormones that influence your menstrual cycle and fertility For some women  your menstrual cycle and fertility For some..."
          />
          <HomeSmallBlogContent
            name="Dr. Muhittin Topalak"
            date="21.07.2023"
            tag="Health"
            title="8 Rules of Kito diet for adult womens"
            text="The keto diet could impact stress and  hormones that influence your menstrual cycle and fertility For some women  your menstrual cycle and fertility For some..."
          />
        </div>
      </div>
    </section>
    <section class="q-mt-xl">
      <HomeVideoSection></HomeVideoSection>
    </section>
    <section class="q-mt-xl">
      <div class="container">
        <div class="row">
          <PublicColoredLineHeader color="#0069FF" text="Testimonials" />
        </div>
        <div class="row q-mt-md q-mb-xl">
          <div class="col-md-6 text-left">
            <div class="text-h3 text-weight-bold" style="color: #0f172a">
              Out patients feedback
            </div>
            <div class="text-h3 text-weight-bold" style="color: #0069ff">
              about us
            </div>
          </div>
          <div class="col-md-6 column justify-end">
            <div class="text-body1 text-left">
              Experience a free 15-minute session and unlock your potential for
              optimal well-being
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <PatientFeedBackSection></PatientFeedBackSection>
    </section>
    <section class="q-mt-xl"><HomeFaqSection /></section>
    <section class="container">
      <SubscribeNewsletter />
    </section>
  </div>
</template>

<script lang="ts" setup>
import {
  PublicFaqItem,
  PublicPatientFeedback,
  PublicFooter,
  PatientFeedBackSection,
  HomeSmallBlogContent,
  PublicColoredLineHeader,
  HomeVideoSection,
  HomeVerticalBlogContent,
  HomeExpertiseCard,
  BlogListingItem,
  SearchInput,
  InhaleSection,
  SubscribeNewsletter,
  CommentCard,
  FilterSideBar,
  HomePageHeader,
  SubscriptionCard,
  ConsultantCard,
  PopularConsultantSection,
  HomeFaqSection,
} from "@/components";
import FeedbackCarousel from '@/components/FeedbackCarousel.vue';

const expertiseDummyData = [
  "Stress Management",
  "Relationship Counseling",
  "Task Loyalty",
  "Yoga Instruction",
  "Anger Management",
  "Nutrition Coaching",
  "Nutrition Coaching",
  "Addiction Counseling",
  "Mindfulness Practice",
];
</script>

<style scoped>
.marquee-wrapper {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.marquee-content {
  display: flex;
  width: calc(200% + 20px); /* İçeriğin toplam genişliği */
  animation: marquee 20s linear infinite;
}

.marquee-item {
  flex: 0 0 auto;
  margin-right: 20px; /* Kartlar arasındaki boşluk */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>


