<!-- // src/views/Public/EmergencyResources.vue -->
<template>
    <div class="consultant-search-header">
      <div class="container">
        <div class="column q-pt-xl">
          <h1 class="page-title-m">Global Emergency Resources</h1>
          <p class="consultant-search-header-text">
         Your quick access to global emergency numbers and services worldwide.For urgent help,refer to the following emergency contacts in your region.
          </p>
        </div>
      </div>
    </div>
    <section class="container q-my-xl">
      <div class="row q-col-gutter-md q-mt-md">
        <div class="col-md-4 col-sm-6 col-12" v-for="(resource, index) in emergencyResources" :key="index">
          <q-card class="resource-card">
            <q-card-section>
              <div class="row items-center">
                <q-img :src="resource.flag" class="flag-icon" />
                <div class="col">
                  <div class="resource-country">{{ resource.country }}</div>
                </div>
              </div>
              <div class="q-mt-md">
                <div v-for="(contact, idx) in resource.contacts" :key="idx" class="contact-item">
                  <div class="contact-number">{{ contact.number }}</div>
                  <div class="contact-description">{{ contact.description }}</div>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <!-- add qbtn load more -->


        </div>
      </div>
      <q-btn
        class="text-capitalize"
        outline
        style="
          margin-top: 5%;
          color: #0069ff;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="More"
      />
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emergencyResources = ref([
  {
    country: 'United States',
    flag: 'path/to/flag/usa.png',
    contacts: [
      { number: '911', description: 'Emergency' },
      { number: '1-800-222-1222', description: 'Poison Control Center' },
      { number: '1-800-273-8255', description: 'National Suicide Prevention Lifeline' },
      { number: '1-800-799-7233', description: 'National Domestic Violence Hotline' },
    ],
    link: '#',
  },
  {
    country: 'Turkey',
    flag: 'path/to/flag/turkey.png',
    contacts: [
      { number: '112', description: 'General Emergency' },
      { number: '155', description: 'Police Emergency' },
      { number: '110', description: 'Fire Department' },
      { number: '182', description: 'Medical Emergency' },
    ],
    link: '#',
  },
  {
    country: 'United Kingdom',
    flag: 'path/to/flag/uk.png',
    contacts: [
      { number: '999 or 112', description: 'General Emergency' },
      { number: '116 123', description: 'Samaritans (Suicide Prevention)' },
      { number: '0808 2000 247', description: 'National Domestic Violence Helpline' },
      { number: '111', description: 'NHS Non-emergency Medical Helpline' },
    ],
    link: '#',
  },
  {
    country: 'Germany',
    flag: 'path/to/flag/germany.png',
    contacts: [
      { number: '112', description: 'General Emergency (Fire & Medical)' },
      { number: '110', description: 'Police Emergency' },
      { number: '0800 111 0111', description: 'Telefonseelsorge (Crisis Helpline)' },
      { number: '116 117', description: 'Non-Emergency Medical Service' },
    ],
    link: '#',
  },
  {
    country: 'Canada',
    flag: 'path/to/flag/canada.png',
    contacts: [
      { number: '911', description: 'General Emergency' },
      { number: '1-833-456-4566', description: 'Canada Suicide Prevention Service' },
      { number: '1-866-925-4419', description: 'Domestic Violence Helpline' },
      { number: '1-800-268-9017', description: 'Emergency Medical Assistance' },
    ],
    link: '#',
  },
  {
    country: 'Australia',
    flag: 'path/to/flag/australia.png',
    contacts: [
      { number: '000', description: 'General Emergency' },
      { number: '13 11 14', description: 'Lifeline (Crisis Support)' },
      { number: '1800 737 732', description: '1800 RESPECT (Domestic Violence)' },
      { number: '13 11 14', description: 'Non-emergency medical assistance' },
    ],
    link: '#',
  },
]);
</script>

<style scoped>
.consultant-search-header {
  background-color: #1e293b;
  background-image: url("../../../public/img/HeaderPattern.svg");
  background-repeat: no-repeat;
  padding-top: 2%;
  padding-bottom: 2%px;
}

.consultant-search-header h1 {
  color: #f9f9f9;
  text-align: center;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
}

.consultant-search-header-text {
  color: #cbd5e1;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.resource-card {
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.flag-icon {
  width: 24px;
  height: 24px;
}

.resource-country {
  font-size: 18px;
  font-weight: 600;
  color: #0f172a;
}

.contact-item {
  margin-bottom: 8px;
}

.contact-number {
  font-size: 16px;
  font-weight: 700;
  color: #0069ff;
}

.contact-description {
  font-size: 14px;
  color: #475569;
}

.resource-link {
  color: #34d399;
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;
}
</style>
