<!-- src/views/Public/ForgotPasswordView.vue -->
<template>
  <div class="row">
    <div class="col-md-6 gt-sm">
      <AuthLeftSwiper imageUrl="/img/YogaRegister.png" />
    </div>
    <div
      class="col-md-6 col-sm-12 col-xs-12 q-pa-xl"
      style="
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <span
        style="
          font-family: 'Red Hat Display';
          font-size: 24px;
          font-weight: 700;
          line-height: 150%;
          text-align: start;
          color: #1e293b;
        "
      >
        {{ currentStep === 'email' ? 'Forgot Password' : 'Reset Password' }}
      </span>
      <span
        class="q-mt-sm"
        style="
          font-family: 'Red Hat Display';
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          text-align: start;
          color: #64748b;
        "
      >
        {{ 
          currentStep === 'email' 
            ? 'Enter your email to reset your password' 
            : currentStep === 'code' 
              ? 'Enter the reset code sent to your email' 
              : 'Enter your new password'
        }}
      </span>
      <!-- Email input -->
      <q-input
        v-if="currentStep === 'email'"
        class="q-mt-xl"
        v-model="email"
        placeholder="Email"
        borderless
        style="
          border: 1px solid #e2e8f0;
          border-radius: 16px;
          padding-left: 16px;
          font-family: 'Red Hat Display';
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          color: #94a3b8;
        "
      >
        <template v-slot:prepend>
          <q-img
            src="/img/icons/mailLight.svg"
            alt=""
            style="width: 24px; height: 24px"
          />
        </template>
      </q-input>

      <!-- Reset code input -->
      <div v-if="currentStep === 'code'" class="q-mt-xl">
        <h2 class="text-h5 q-mb-md">Verification Code</h2>
        <p class="text-subtitle1 q-mb-lg">Please enter the verification code sent to your email.</p>
        <div class="row q-col-gutter-md justify-center">
          <q-input
            v-model="otpValue"
            placeholder="Enter code"
            class="verification-input"
            maxlength="6"
            @input="handleOtpChange"
            style="width: 100%; text-align: center;"
          />
        </div>
        <div class="text-center q-mt-lg">
          <template v-if="resendTimer > 0">
            <p class="text-caption">Send again ({{ formatTime(resendTimer) }})</p>
          </template>
          <q-btn v-else flat color="primary" label="Send again" @click="resendCode" />
        </div>
      </div>
      <!-- New password inputs -->
      <template v-if="currentStep === 'password'">
        <q-input
          class="q-mt-xl"
          v-model="newPassword"
          placeholder="New Password"
          type="password"
          borderless
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
          "
        >
          <template v-slot:prepend>
            <q-img
              src="/img/icons/lock.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>
        <q-input
          class="q-mt-md"
          v-model="confirmPassword"
          placeholder="Confirm New Password"
          type="password"
          borderless
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
          "
        >
          <template v-slot:prepend>
            <q-img
              src="/img/icons/lock.svg"
              alt=""
              style="width: 24px; height: 24px"
            />
          </template>
        </q-input>
      </template>
      <div class="row q-mt-lg q-gutter-md">
        <q-btn v-if=" currentStep !== 'email'"
          style="
            background-color: #0069ff;
            color: #ffffff;
            font-family: Red Hat Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: none;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 16px;
            margin-top: 32px;
          "
          outline
          color="primary"
          label="Back"
          @click="goBack"
          class="col"
        />
        <q-btn 
          color="primary"
          style="
            background-color: #0069ff;
            color: #ffffff;
            font-family: Red Hat Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: none;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 16px;
            margin-top: 32px;
          "
          :label="buttonLabel"
          @click="handleSubmit"
          :disable="!isValidInput || (currentStep === 'email' && resendTimer > 0)"
          class="col"
        />
      </div>
      <span
        class="q-mt-lg"
        style="
          font-family: 'Red Hat Display';
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          text-align: center;
          color: #64748b;
        "
      >
        Remember your password?
        <router-link to="/login" style="color: #0069ff; font-weight: 700; text-decoration: none;">Sign In</router-link>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted, watch } from "vue";
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';
import { AuthLeftSwiper } from "@/components";
import { useUserStore } from "@/store/user";

const $q = useQuasar();
const userStore = useUserStore();
const router = useRouter();
const otpValue = ref("");

const email = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const currentStep = ref<'email' | 'code' | 'password'>('email');
const resendTimer = ref(0);
let resendInterval: number | null = null;

const isValidEmail = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email.value);
});

const isValidPassword = computed(() => {
  return newPassword.value.length >= 8 && newPassword.value === confirmPassword.value;
});

const isValidInput = computed(() => {
  if (currentStep.value === 'email') return isValidEmail.value;
  if (currentStep.value === 'code') return otpValue.value.length === 6;
  return isValidPassword.value;
});

const buttonLabel = computed(() => {
  if (currentStep.value === 'email') return 'Send Reset Code';
  if (currentStep.value === 'code') return 'Verify Code';
  return 'Reset Password';
});

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const goBack = () => {
  if (currentStep.value === 'code') {
    currentStep.value = 'email';
  } else if (currentStep.value === 'password') {
    currentStep.value = 'code';
  }
};

const startResendTimer = () => {
  resendTimer.value = 180; // 3 dakika
  resendInterval = setInterval(() => {
    resendTimer.value--;
    if (resendTimer.value <= 0) {
      clearInterval(resendInterval!);
    }
  }, 1000);
};

const handleOtpChange = (value: string) => {
  otpValue.value = value.slice(0, 6); // Ensure only 6 characters
};

const resendCode = async () => {
  if (resendTimer.value > 0) return;

  const success = await userStore.forgotPassword(email.value);
  if (success) {
    $q.notify({
      type: 'positive',
      message: 'Reset code resent to your email.',
      position: 'top',
    });
    startResendTimer();
  } else {
    $q.notify({
      type: 'negative',
      message: userStore.errorMessage || 'Failed to resend code. Please try again.',
      position: 'top',
    });
  }
};

const handleSubmit = async () => {
  if (currentStep.value === 'email') {
    if (isValidEmail.value) {
      const success = await userStore.forgotPassword(email.value);
      if (success) {
        $q.notify({
          type: 'positive',
          message: 'Reset code sent to your email.',
          position: 'top',
        });
        currentStep.value = 'code';
        startResendTimer();
      } else {
        $q.notify({
          type: 'negative',
          message: userStore.errorMessage || 'An error occurred. Please try again.',
          position: 'top',
        });
      }
    }
  } else if (currentStep.value === 'code') {
    const success = await userStore.validateResetPasswordToken(otpValue.value);
    console.log(success);
    console.log(typeof success);
    if (success) {
      $q.notify({
        type: 'positive',
        message: 'Code verified successfully.',
        position: 'top',
      });
      currentStep.value = 'password';
    } else {
      $q.notify({
        type: 'negative',
        message: userStore.errorMessage || 'Failed to verify code. Please try again.',
        position: 'top',
      });
      currentStep.value = 'code';
    }
    
  } else {
    if (isValidPassword.value) {
      const success = await userStore.resetPassword(otpValue.value, newPassword.value);
      if (success) {
        $q.notify({
          type: 'positive',
          message: 'Your password has been reset successfully. You can now log in with your new password.',
          position: 'top',
        });
        router.push('/login');
      } else {
        $q.notify({
          type: 'negative',
          message: userStore.errorMessage || 'Failed to reset password. Please try again.',
          position: 'top',
        });
      }
    }
  }
};

onUnmounted(() => {
  if (resendInterval) clearInterval(resendInterval);
});
</script>

<style scoped>


.verification-input :deep(.q-field__native) {
  font-size: 16px;
  text-align: center;
}

.text-center {
  text-align: center;
}
</style>
