<!-- // src/views/Public/ConsultantDetails.vue -->
<template>
  <ConsultantDeatilsHeader></ConsultantDeatilsHeader>
  <section class="container" style="margin-top: 65px">
    <div
      class="text-left"
      style="
        color: var(--greyscale-900, #0f172a);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      "
    >
      Overview
    </div>
    <div class="text-left">
      <p class="text-body1 q-mt-md">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
        consectetur, perferendis ducimus exercitationem modi nesciunt sit alias
        nam deserunt, repellat eaque at, tempore laboriosam culpa officiis?
        Itaque fuga dolor, saepe cum quaerat assumenda alias illo exercitationem
        voluptatibus voluptatum modi unde maxime totam ipsa esse quisquam.
        Aliquid consequatur cupiditate corrupti cumque, nostrum, officia ratione
        deserunt pariatur sapiente similique tenetur voluptatibus delectus?
      </p>

      <p class="text-body1">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas minus
        maiores in praesentium? In cupiditate, iste, tempora veniam modi numquam
        aliquam non rem earum molestias, laborum ratione eveniet autem! Eum
        velit magnam quia asperiores quidem unde consectetur optio illo adipisci
        rem! Magnam veniam ducimus vero facere ipsam excepturi possimus,
        perferendis quaerat expedita pariatur modi dolorem laborum reiciendis
        libero debitis ullam consequatur. Consequatur culpa et explicabo, itaque
        amet, id perferendis quam doloribus repudiandae reiciendis nisi at quis.
        Perspiciatis praesentium, molestias dignissimos iste, hic doloremque
        amet quas accusantium magnam modi eos nihil.
      </p>
    </div>
  </section>
  <section class="container q-mt-lg">
    <div class="row q-col-gutter-x-lg">
      <div class="col-md-4 col-12">
        <ConsultantEducationCard
          title="Education"
          :data="[
            { year: '2010 - 2014', text: 'Akdeniz Üniversitesi' },
            { year: '2010 - 2014', text: 'Akdeniz Üniversitesi' },
            { year: '2010 - 2014', text: 'Akdeniz Üniversitesi' },
          ]"
        />
      </div>
      <div
        class="col-md-4 col-12 q-mt-lg-none q-mt-md-none q-mt-sm-lg q-mt-xs-lg"
      >
        <ConsultantEducationCard
          title="Certificate"
          :data="[
            {
              year: '2020',
              text: 'Psychology Certificate Istanbul Kultur University Psychology',
            },
            { year: '2021', text: 'Law Kilbal University' },
          ]"
        />
      </div>
      <div
        class="col-md-4 col-12 q-mt-lg-none q-mt-md-none q-mt-sm-lg q-mt-xs-lg"
      >
        <ConsultantExpertCard
          :data="[
            'Anxiety Disorders',
            'Child Development',
            'Relationship Counseling',
            'Marriage Counseling',
            'Depression',
          ]"
        ></ConsultantExpertCard>
      </div>
    </div>
  </section>
  <section style="margin-top: 2%;">
    <div class="events-section-bg">
      <q-text class="text-h5 text-weight-bold" style="color: #34d399">
        Events
      </q-text>
      <div class="row q-mt-xl q-col-gutter-xl-md q-col-gutter-y-md justify-center">
        <ConsultatDetailEvents v-for="item in 3" :key="item" />
      </div>
      <q-btn
        class="text-capitalize"
        outline
        style="
          margin-top: 5%;
          color: #0069ff;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="Load More Events"
      />
    </div>
  </section>
  <section class="container q-mt-xl q-mb-lg">
    <div class="flex q-gutter-sm items-center q-mb-md">
      <span class="text-h5 text-weight-bold" style="color: #0f172a">
        Testimonials
      </span>
      <span
        style="
          color: #475569;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
        "
        >(24)</span>
      <div class="flex q-gutter-xs">
        <span
          style="
            color: #34d399;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 16px */
          "
          >4,3</span
        >
        <img src="/img/icons/star-green.svg" />
      </div>
    </div>
    <div>
      <TestimonialCard
        name="Alex De Souza"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      ></TestimonialCard>
      <q-separator class="q-mx-none q-mt-md" inset />

      <q-btn
        class="q-mt-md text-capitalize"
        outline
        style="
          color: #34d399;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="Load More Comment"
        icon="img:/img/icons/plus.svg"
      />
    </div>
  </section>
  <section class="container q-mb-xl">
    <div class="text-h5 text-weight-bold text-left" style="color: #0f172a">
      Post's from Dr. Celine Dion
    </div>
    <div class="row q-mt-md q-col-gutter-x-lg q-col-gutter-y-md">
      <div v-for="item in 3" class="col-md-4">
        <BlogListingItem
          title="8 Rules of Mental Health"
          text="Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from..."
          coverImageUrl="https://cdn.quasar.dev/img/mountains.jpg"
          userImage="https://cdn.quasar.dev/img/avatar6.jpg"
          userName="deniz123"
          date="10.01.2001"
          tag="YYYY"
        />
      </div>
    </div>
    <div class="flex justify-center q-mt-md">
      <q-btn
        class="text-capitalize"
        outline
        style="
          color: #0069ff;
          border-radius: 16px;
          padding: 8px;
          width: 236px;
          height: 48px;
        "
        label="Load More Comment"
        icon="img:/img/icons/plus.svg"
      />
    </div>
  </section>
  <section class="container q-mb-xl">
    <div class="text-h5 text-weight-bold text-left" style="color: #0f172a">
      Schedule
    </div>
    <q-card class="calendar-card q-mt-md">
      <q-card-section>
        <div class="row items-center justify-between q-mb-md">
          <div class="text-h6">{{ currentMonth }} {{ currentYear }}</div>
        </div>
        <div class="row q-col-gutter-sm q-mb-md items-center">
          <div class="col-auto">
            <q-btn flat round color="grey" icon="chevron_left" @click="previousDay" />
          </div>
          <div v-for="day in visibleDays" :key="day.date" class="col">
            <q-btn
              :class="{ 'bg-primary text-white': isSelected(day.date) }"
              :label="day.dayOfMonth"
              :flat="!isSelected(day.date)"
              round
              @click="selectDate(day.date)"
            />
            <div class="text-caption text-center">{{ day.dayName }}</div>
          </div>
          <div class="col-auto">
            <q-btn flat round color="grey" icon="chevron_right" @click="nextDay" />
          </div>
        </div>
        <div class="row q-col-gutter-x-md q-mb-md">
          <q-btn outline color="secondary" label="Events" class="col" @click="showEvents = true; showMeetings = false" />
        </div>
        <div v-if="showEvents">
          <div v-for="event in events" :key="event.id" class="q-mb-md">
            <q-expansion-item
              :label="event.title"
              :caption="event.time"
              icon="event"
            >
              <q-card>
                <q-card-section>
                  <div>{{ event.description }}</div>
                  <div class="q-mt-sm">
                    <q-chip color="purple" text-color="white" size="sm">
                      {{ event.type }}
                    </q-chip>
                    <q-chip v-if="event.location" color="teal" text-color="white" size="sm">
                      {{ event.location }}
                    </q-chip>
                    <q-btn v-if="event.location" style="          border-radius: 10px !important;
                      background-color: #0069ff !important;" color="primary"  size="l">
                      Book Now
                    </q-btn>
                  </div>
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </section>
</template>

<script setup lang="ts">
import {
  ConsultantDeatilsHeader,
  ConsultantEducationCard,
  ConsultatDetailEvents,
  ConsultantExpertCard,
  CommentCard,
  TestimonialCard,
  BlogListingItem,
  ConsultantCard,
} from "@/components";

import { ref, computed } from 'vue';

const currentDate = ref(new Date());
const showMeetings = ref(true);
const showEvents = ref(false);

const currentMonth = computed(() => {
  return currentDate.value.toLocaleString('default', { month: 'long' });
});

const currentYear = computed(() => {
  return currentDate.value.getFullYear();
});

const visibleDays = computed(() => {
  const days = [];
  const startOfWeek = new Date(currentDate.value);
  startOfWeek.setDate(currentDate.value.getDate() - 2);

  for (let i = 0; i < 5; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    days.push({
      date: day.toISOString().split('T')[0],
      dayOfMonth: day.getDate(),
      dayName: day.toLocaleString('default', { weekday: 'short' })
    });
  }
  return days;
});

const events = ref([
  {
    id: 1,
    title: 'Team Building Workshop',
    time: '2:00 PM - 5:00 PM',
    description: 'Annual team building event to improve collaboration and communication.',
    type: 'Workshop',
    location: 'Minimum Stress Online Platform'
  },
  {
    id: 2,
    title: 'Product Launch',
    time: '7:00 PM - 9:00 PM',
    description: 'Launch event for our new product line.',
    type: 'Corporate Event',
    location: 'Main Auditorium'
  },
  {
    id: 3,
    title: 'Charity Fundraiser',
    time: '6:00 PM - 10:00 PM',
    description: 'Annual charity event to raise funds for local community projects.',
    type: 'Fundraiser',
    location: 'Minimum Stress Online Platform'
  }
]);

const isSelected = (date: string) => {
  return date === currentDate.value.toISOString().split('T')[0];
};

const selectDate = (date: string) => {
  currentDate.value = new Date(date);
};

const nextDay = () => {
  currentDate.value.setDate(currentDate.value.getDate() + 1);
  currentDate.value = new Date(currentDate.value);
};

const previousDay = () => {
  currentDate.value.setDate(currentDate.value.getDate() - 1);
  currentDate.value = new Date(currentDate.value);
};

defineProps<{ price: string }>();
const items = [
  "Anxiety Disorders",
  "Child Development",
  "Relationship Counseling",
  "Marriage Counseling",
  "Depression",
];
</script>

<style>
.events-section-bg {
  background-color: #1e293b;
  background-image: url("../../../public/img/HeaderPattern.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top:2%;
  padding-bottom:2%;
}
.calendar-card {
  width: 100%;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination-btn {
  border-radius: 16px;
  padding: 8px;
  width: 236px;
  height: 48px;
}
</style>
