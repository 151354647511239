<!-- // src/components/ConsultantCard.vue -->
<template>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <q-card style="border-radius: 16px; border: 1px solid #e2e8f0">
      <q-card-section>
        <div class="row q-col-gutter-lg">
          <div class="col-md-3">
            <q-img
              class="consultant-image"
              src="https://cdn.quasar.dev/img/parallax2.jpg"
              style="height: 64px; width: 64px"
            />
          </div>
          <div class="col-md-6 column text-left">
            <div class="text-body2 text-weight-bold" style="color: #0f172a">
              Muhittin Topalak
            </div>
            <div class="text-caption">Nutritionist</div>
            <div class="q-mt-sm">
              <div class="row q-gutter-sm">
                <span
                  style="
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                  "
                  >4,7</span
                ><span style="display: flex"
                  ><q-img
                    style="width: 16px; height: 16px"
                    src="/img/icons/star-solid.svg"
                  ></q-img
                ></span>
                <div>
                  <span class="consultant-review">(32 reviews)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <img src="/img/icons/free-icon.svg" />
          </div>
        </div>
        <div class="row q-mt-md">
          <q-chip
            style="background: var(--greyscale-100, #f1f5f9)"
            v-for="item in items"
            ><span class="consultant-chip">{{ item }}</span></q-chip
          >
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="column">
          <div class="row text-left justift-start items-start">
            <span
              v-if="price.toString().split('.').length == 1"
              style="
                font-family: 'Red Hat Display';
                font-size: 32px;
                font-weight: 700;
                line-height: 32px;
                text-align: right;
                color: #0069ff;
              "
            >
              ${{ price }}
            </span>
            <div v-if="price.toString().split('.').length != 1">
              <span
                style="
                  font-family: 'Red Hat Display';
                  font-size: 32px;
                  font-weight: 700;
                  line-height: 32px;
                  text-align: right;
                  color: #0069ff;
                "
              >
                ${{ price.toString().split(".")[0] }},
              </span>
              <span
                style="
                  font-family: 'Red Hat Display';
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 20px;
                  margin-left: -4px;
                  color: #0069ff;
                "
              >
                {{ price.toString().split(".")[1] }}
              </span>
            </div>
          </div>
          <div class="text-caption text-left">
            (Video 45 min./ Voice Call 45 min.)
          </div>
        </div>
      </q-card-section>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-lg-6">
          <router-link to="/consultants/1">
          <q-btn
            style="
              border-radius: 10px !important;
              background-color: #fff !important;
            "
            rounded
            text-color="black"
            label="See Details"
            size="md"
            class="full-width text-capitalize text-weight-bold"
          />
        </router-link>
        </div>
        <div class="col-lg-6">

          <q-btn
            style="
              border-radius: 10px !important;
              background-color: #0069ff !important;
            "
            rounded
            color="primary"
            label="Book Appointment"
            size="md"
            class="full-width text-capitalize text-weight-bold"
            @click="showModal = true"
          />

              <AppointmentModal
                  :is-open="showModal"
                  @update:is-open="showModal = $event"
                  :consultant="consultantData"
                  @book="handleBooking"
              />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import AppointmentModal from "./AppointmentModal.vue";

const showModal = ref(false);
const consultantData = {
  name: 'Jane Doe',
  profession: 'Social Life Coach',
  rating: 4.7,
  avatar: '/img/Test_PP.png'
};

const handleBooking = (bookingDetails: any) => {
  console.log('Booking details:', bookingDetails);
  // Handle the booking logic here
};

defineProps<{ price: string }>();
const items = [
  "Anxiety Disorders",
  "Child Development",
  "Relationship Counseling",
  "Marriage Counseling",
  "Depression",
];
</script>
