<!-- // src/components/SearchInput.vue -->
<template>
  <q-btn-group
    push
    class="search-group q-py-sm q-pl-sm row no-wrap"
    style="width: 100%; border-radius: 16px"
    :style="{ backgroundColor: bgColor ? bgColor : 'transparent' }"
  >
    <q-input
      class="consultant-search col-xs-8 col-sm-6 col-md-7 col-lg-8"
      borderless
      v-model="state.text"
      label="Search Consultant"
      style="border-right: 1px solid #e0e0e0"
    />
    <q-btn-dropdown
      class="category-search col-xs-3 col-sm-3 col-md-3 col-lg-2"
      label="Category"
      dropdown-icon="img:img/icons/arrowDown.svg"
      style="margin-right: 1px" 
    >
      <q-list>
        <q-item clickable v-close-popup>
          <q-item-section>
            <q-item-label>Photos</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup>
          <q-item-section>
            <q-item-label>Videos</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup>
          <q-item-section>
            <q-item-label>Articles</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <div class="search-button-container col-xs-1 col-sm-3 col-md-2 col-lg-2 row justify-end q-pr-md-md q-pr-sm-md">
      <q-btn
        class="search-button"
        style="background-color: #0069ff; border-radius: 16px; height: 100%; margin-left: 4px;" 
        rounded
        icon="img:img/icons/search.svg"
      />
    </div>
  </q-btn-group>
</template>

<script setup lang="ts">
import { reactive } from "vue";
defineProps<{ bgColor?: string }>();

const state = reactive({ text: "" });
</script>

<style scoped>
.search-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.search-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-button {
  width: 100%;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media Queries for responsive design */
@media (max-width: 599px) {
  .search-group {
    flex-wrap: nowrap;
    padding: 4px !important;
  }

  .consultant-search {
    flex: 1 1 auto;
    min-width: 0;
  }

  .category-search {
    padding: 0 4px;
    min-width: 90px;
  }

  .category-search :deep(.q-btn__content) {
    flex-wrap: nowrap;
  }

  .category-search :deep(.q-btn__label) {
    font-size: 12px;
    padding-right: 4px;
  }

  .search-button-container {
    padding-right: 0 !important;
  }

  .search-button {
    margin-left: 2px !important;
  }
}

@media (max-width: 359px) {
  .category-search {
    min-width: 70px;
  }

  .category-search :deep(.q-btn__label) {
    font-size: 10px;
  }
}
</style>