<!-- // src/components/SearchInputNoCategory.vue -->
<template>
    <q-btn-group
      push
      class="q-py-sm q-pl-sm justify-between"
      style="width: 100%; border-radius: 16px; background-color: #FFFFFF;"
    >
      <q-input
        class="consultant-search col-md-9 col-sm-4"
        borderless
        v-model="state.text"
        label="Search"
      />
      <div class="q-pr-sm">
        <q-btn
          style="background-color: #0069ff; border-radius: 16px; max-width: 56px; height: 100%;"
          rounded
          icon="img:img/icons/search.svg"
        />
      </div>
    </q-btn-group>
  </template>
  <script setup lang="ts">
  import { reactive } from "vue";
  
  const state = reactive({ text: "" });
  </script>
  
  <style>
  .category-search span.block {
    color: var(--greyscale-900, #0f172a);
    text-align: right;
    text-transform: capitalize;
    /* Body Large (Medium) */
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  </style>
  