<!-- // src/views/Public/Blog.vue -->
<template>
    
    <div style="position: relative">
        <img
            src="/img/HeaderPattern.svg"
            alt="Header Background"
            style="width: 100%; height: 320px; object-fit: cover; background-color: #1e293b;"
        />
        <div
            class="container"
            style="position: absolute; bottom: 56px; left: 0; right: 0"
        >
            <div class="row">
                <div class="col-12 q-mb-md">
                    <PublicColoredLineHeader color="#0069FF" text="Blog" />
                </div>
                <div class="col-md-8">
                    <div class="text-h3 text-weight-bold text-left text-white">
                        Our Best Blogs
                    </div>
                    <div class="text-h3 text-weight-bold text-left text-primary">
                        For You
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container">
        <Group :posts="blogPosts" :hasMorePosts="hasMorePosts" @loadMore="loadMoreBlogs" />
    </div>

</template>


<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/utils/config';
import Group from '@/components/Group.vue';
import PublicColoredLineHeader from '@/components/PublicColoredLineHeader.vue';

interface QueryDto {
    skip: string;
    limit: string;
    filters?: {
        [key: string]: { value: string | null; matchMode: string; operator: string }[];
    };
    sortField?: string;
    sortOrder?: string;
}

const blogPosts = ref([]);
const currentPage = ref(1);
const pageSize = 6;
const hasMorePosts = ref(true);

const createQueryDto = (page: number): QueryDto => ({
    skip: ((page - 1) * pageSize).toString(),
    limit: pageSize.toString(),
    filters: {
        status: [{ value: 'approved', matchMode: 'equals', operator: 'and' }]
    },
    sortField: 'createdAt',
    sortOrder: '-1'
});

const loadBlogPosts = async () => {
    try {
        const queryDto = createQueryDto(currentPage.value);
        const response = await axios.get(`${API_BASE_URL}/blog`, { params: queryDto });
        const newPosts = response.data.data;
        
        if (newPosts.length < pageSize) {
            hasMorePosts.value = false;
        }

        blogPosts.value = [...blogPosts.value, ...newPosts];
    } catch (error) {
        console.error('Error loading blog posts:', error);
    }
};

const loadMoreBlogs = async () => {
    currentPage.value++;
    await loadBlogPosts();
};

onMounted(loadBlogPosts);
</script>

<style scoped>
.content-container {
    margin-top: 40px;
}

.q-pa-md {
    padding: 16px;
}

.large-blog-card {
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
}

.large-blog-image {
    height: 200px;
    object-fit: cover;
}

.text-bold {
    font-weight: bold;
}

.text-body2 {
    color: #333;
    line-height: 1.6;
}

.q-card {
    background-color: #ffffff;
}

.q-card-section {
    padding: 16px;
}

.q-card-actions {
    border-top: 1px solid #e0e0e0;
}

.q-btn {
    color: #0069ff;
}

.text-white {
    color: var(--greyscale-50, #f8fafc);
}

.text-primary {
    color: var(--primary, #0069ff);
}
</style>
