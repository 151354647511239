<!-- // src/views/Public/ConsultantApplication.vue:  -->
<template>
 <div class="row no-wrap" style="height: 100vh; overflow: hidden;">
    <div class="col-md-6 gt-sm" style="height: 100%;">
      <AuthLeftSwiper imageUrl="/img/YogaRegister.png" />
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 q-pa-xl scroll-y" style="
          text-align: start;
          display: flex;
          overflow-y: auto;
          padding: 2rem;
          flex-direction: column;
        ">
      <div style="
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: center;
          ">
        <span style="
              font-family: 'Red Hat Display';
              font-size: 24px;
              font-weight: 700;
              line-height: 150%;
              text-align: start;
              color: #1e293b;
            ">
          Apply to become our team member
        </span>
        <span class="q-mt-sm" style="
              font-family: 'Red Hat Display';
              font-size: 14px;
              font-weight: 500;
              line-height: 150%;
              text-align: start;
              color: #64748b;
            ">
          Welcome ! Please enter your detail
        </span>
        
        <profile-picture-upload v-model="profilePhoto" @update:modelValue="validateProfilePhoto" />
        <q-input
    class="form-field"
    v-model="name"
    placeholder="Name and Surname"
    borderless
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/user.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-input>
  <!-- Email -->
  <q-input
    class="form-field"
    v-model="email"
    placeholder="Email"
    borderless
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/mailLight.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-input>
  <!-- Password -->
  <q-input
    class="form-field"
    v-model="password"
    type="password"
    placeholder="Password"
    borderless
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/lock.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-input>

  <!-- Select Language -->
  <q-select
    class="form-field"
    v-model="language"
    :options="languages"
    option-label="label"
    option-value="value"
    label="Select Language"
    borderless
    use-input
    hide-selected
    fill-input
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/language.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-select>
  <!-- Select Specialization -->
  <q-select
    class="form-field"
    v-model="specialization"
    :options="specializations"
    option-label="label"
    option-value="value"
    label="Select Specialization"
    borderless
    emit-value
    use-input
    map-options
  >
    <template v-slot:prepend>
      <q-img src="img/icons/menuProfessionNotSelected.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-select>
  <!-- Experience -->
  <q-select
    class="form-field"
    v-model="experience"
    :options="experienceOptions"
    label="Experience"
    borderless
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/menuProfessionNotSelected.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-select>

  <!-- Languages -->
  <q-select
    class="form-field"
    v-model="selectedLanguages"
    :options="languages"
    label="Languages"
    borderless
    multiple
    use-chips
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/language.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-select>

  <!-- Timezone -->
  <q-input
    class="form-field"
    v-model="timezone"
    label="Timezone"
    borderless
  >
    <template v-slot:prepend>
      <q-img src="/img/icons/time.svg" alt="" style="width: 24px; height: 24px" />
    </template>
  </q-input>

  <!-- Add Education -->
  <div v-for="(education, index) in consultantEducation" :key="index" class="q-mb-md">
    <q-card class="form-card">
      <q-card-section>
        <q-input
          class="form-field"
          v-model="education.degree"
          label="Degree"
          borderless
        >
          <template v-slot:prepend>
            <q-img src="/img/icons/menuProfessionNotSelected.svg" alt="" style="width: 24px; height: 24px" />
          </template>
        </q-input>

        <q-input
          class="form-field"
          v-model="education.institution"
          label="Institution"
          borderless
        >
          <template v-slot:prepend>
            <q-img src="/img/icons/graduate.svg" alt="" style="width: 24px; height: 24px" />
          </template>
        </q-input>

        <q-input
          class="form-field"
          v-model.number="education.graduateYear"
          type="number"
          label="Graduate Year"
          borderless
        >
          <template v-slot:prepend>
            <q-img src="/img/icons/date.svg" alt="" style="width: 24px; height: 24px" />
          </template>
        </q-input>

        <q-btn
          dense
          flat
          round
          icon="delete"
          color="negative"
          @click="removeEducation(index)"
          class="q-mt-xs"
        />
      </q-card-section>
    </q-card>
  </div>

  <q-btn
    label="Add Education"
    color="primary"
    icon="add"
    flat
    round
    dense
    @click="addEducation"
    class="q-mt-sm"
  />

        <QPhoneInput v-model="phoneNumber" class="q-mt-sm" :label="'Phone Number'" :searchLabel="'County Code'"
          :outlined="true" :dense="false" borderless @phoneChanged="handlePhoneChanged" />
        <span style="font-size: x-small;"> Submit your Resume</span>
        <q-file
          class="q-mt-sm"
          v-model="resume"
          label="Upload Resume"
          accept=".pdf"
          @update:model-value="validateResume"
          border
          style="
            border: 1px solid #e2e8f0;
            border-radius: 16px;
            padding-left: 16px;
            font-family: 'Red Hat Display';
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: #94a3b8;
            width: 300px;
            height: auto;
          "
        >
          <template v-slot:prepend>
            <q-img src="/img/icons/blueUpload.svg" alt="" style="width: 24px; height: 24px" />
          </template>
        </q-file>
        <span style="font-size: x-small;"> Submit your Certificates</span>
        <q-file
        :model-value="certificates"
    label="Upload Certificates"
    multiple
    accept=".pdf,.jpg,.jpeg,.png"
    border
    @update:model-value="handleCertificatesChange"
    style="
      border: 1px solid #e2e8f0;
      border-radius: 16px;
      padding-left: 16px;
      font-family: 'Red Hat Display';
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      color: #94a3b8;
      width: 300px;
      height: auto;
    "
        >
          <template v-slot:prepend>
            <q-img src="/img/icons/blueUpload.svg" alt="" style="width: 24px; height: 24px" />
          </template>
        </q-file>

  <div v-if="certificates.length > 0" class="q-mt-md certificate-list">
    <q-list bordered padding>
      <q-item v-for="(file, index) in certificates" :key="index" class="q-pa-xs">
        <q-item-section>
          <q-img :src="getFilePreview(file)" alt="Certificate Preview" class="q-mr-sm" style="width: 50px; height: 50px" />
        </q-item-section>
        <q-item-section>
          <div class="text-subtitle2">{{ file.name }}</div>
          <div class="text-caption">Size: {{ formatSize(file.size) }}</div>
        </q-item-section>
        <q-item-section side top>
          <q-btn dense flat round icon="delete" @click="removeCertificate(index)" />
        </q-item-section>
      </q-item>
    </q-list>
  </div>

  <div v-if="certificates.length >= 5" class="q-mt-sm">
    <q-banner type="warning" class="q-px-md">
      Maximum of 5 files can be uploaded.
    </q-banner>
  </div>


        <div v-if="state.errorMessage != null" style="margin-top: 16px">
          <q-img src="/img/icons/alertWarningRed.svg" alt="" width="16px" height="16px" />
          <span style="
                font-family: 'Red Hat Display';
                font-size: 12px;
                font-weight: 400;
                color: #ff6155;
                margin-left: 4px;
              ">
            {{ state.errorMessage }}
          </span>
        </div>
        <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
          <q-checkbox class="q-mt-lg" v-model="rememberMe" size="md" style="
              font-family: 'Red Hat Display';
              font-size: 12px;
              font-weight: 500;
              line-height: 100%;
              text-align: start;
              color: #94a3b8;
            ">
            <span @click.stop>
              I agree to the
              <a href="https://minimum-stress-documents-space.nyc3.cdn.digitaloceanspaces.com/Minimum%20Stress%20LLC%20-%20Privacy%20Policy%20V1.pdf"
                target="_blank" style="font-weight: bold;" @click.stop>Terms & Conditions</a>
              and
              <a href="https://minimum-stress-documents-space.nyc3.cdn.digitaloceanspaces.com/Minimum%20Stress%20LLC%20-%20Terms%20of%20Use%20V1.pdf"
                target="_blank" style="font-weight: bold;" @click.stop>Privacy Policy</a>
            </span>
          </q-checkbox>
        </div>
        <div>
          <q-checkbox class="q-mt-lg" v-model="adult" size="md" label="I am older than 18 years old" style="
                font-family: 'Red Hat Display';
                font-size: 12px;
                font-weight: 500;
                line-height: 100%;
                text-align: start;
                color: #94a3b8;
              " />
        </div>
        <q-btn
    label="Apply"
    :loading="isLoading"
    style="
      background-color: #0069ff;
      color: #ffffff;
      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-transform: none;
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 16px;
      margin-top: 32px;
    "
    @click="register"
    :disable="!isFormValid"
  >
    <template v-slot:loading>
      <q-spinner-facebook />
    </template>
  </q-btn>

      </div>
    </div>
  </div>

  <VerificationDialog
    v-model:isOpen="showVerificationDialog"
    :email="email"
    :phone="formattedPhoneNumber"
    :userId="userId"
    @verificationComplete="handleVerificationComplete"
  />
</template>

<script setup lang="ts">
import { PhoneNumber } from 'libphonenumber-js';
import { ref, watchEffect, onMounted, computed, watch,onUnmounted } from "vue";
import { AuthLeftSwiper, QPhoneInput,ProfilePictureUpload,VerificationDialog  } from "@/components";
import { useUserStore } from "@/store/user";
import { useRouter } from "vue-router";
import { QBtn, QList, QItem, QItemSection, QImg, Notify,useQuasar } from 'quasar';
const isEmailVerified = ref(false);
const isPhoneVerified = ref(false);
const showVerificationDialog = ref(false);
const userId = ref('');

interface LanguageOption {
  label: string;
  value: string;
  code: string;
}
const $q = useQuasar();
const userStore = useUserStore();
const router = useRouter();

const { login, removeErrorMessage } = useUserStore();
const state = useUserStore().$state;

const email = ref("");
const password = ref("");
const name = ref("");
const phoneNumber = ref("");
const language = ref<LanguageOption | null>(null);
const specialization = ref("");
const resume = ref<File | null>(null);
const rememberMe = ref(false);
const adult = ref(false);
const registerRole = ref<string>("customer");
const phoneNumberDetails = ref({ countryCode: '', number: '' });
const profilePhoto = ref<File | null>(null);
const experience = ref("");
const experienceOptions = ['0-2', '2-5', '5-10', '10-20', '20+'];
const certificates = ref<File[]>([]);
  const isLoading = ref(false)

// Hata mesajı için ref oluştur
const errorMessage = ref('');

// UserStore'daki hata mesajını izle
watch(() => userStore.errorMessage, (newErrorMessage) => {
  errorMessage.value = newErrorMessage || '';
});

// Sayfa yüklendiğinde hata mesajını temizle
onMounted(() => {
  userStore.clearErrorMessage();
});

// Sayfa değiştiğinde veya component unmount olduğunda hata mesajını temizle
onUnmounted(() => {
  userStore.clearErrorMessage();
});

const formattedPhoneNumber = computed(() => {
  return phoneNumberDetails.value.countryCode + phoneNumberDetails.value.number;
});

const handlePhoneChanged = (phone: PhoneNumber | undefined) => {
  if (phone) {
    phoneNumberDetails.value.countryCode = `+${phone.countryCallingCode}`;
    phoneNumberDetails.value.number = phone.nationalNumber;
  }
};
const selectedLanguages = ref<LanguageOption[]>([]);
const languages = ref<LanguageOption[]>([
  { label: "English", value: "en", code: "en" },
  { label: "Spanish", value: "es", code: "es" },
  { label: "French", value: "fr", code: "fr" },
  { label: "Turkish", value: "tr", code: "tr" }
]);
const onLanguageChange = (val: LanguageOption | null) => {
  console.log("Language changed:", val);
  language.value = val;
};

const specializations = ref<Array<{ label: string; value: string }>>([
  { label: "Yoga", value: "yoga" },
  { label: "Pilates", value: "pilates" },
  { label: "Meditation", value: "meditation" }
]);
const timezone = ref('UTC');

const consultantEducation = ref<Array<{ degree: string; institution: string; graduateYear: number | null }>>([]);

const addEducation = () => {
  consultantEducation.value.push({ degree: '', institution: '', graduateYear: null });
};

const removeEducation = (index: number) => {
  consultantEducation.value.splice(index, 1);
};

const isFormValid = computed(() => {
  return (
    email.value?.length > 0 &&
    email.value.includes('@') &&
    password.value?.length >= 6 &&
    name.value?.length > 0 &&
    phoneNumber.value?.length > 0 &&
    language.value != null &&
    specialization.value?.length > 0 &&
    adult.value &&
    selectedLanguages.value.length > 0 &&
    timezone.value &&
    consultantEducation.value.length > 0 &&
    profilePhoto.value &&
    resume.value &&
    certificates.value.length > 0 &&
    consultantEducation.value.every(edu => 
      edu.degree && edu.institution && edu.graduateYear
    )
  );
});

const validateProfilePhoto = (file: File | null) => {
  if (file) {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      $q.notify({
        color: 'negative',
        message: 'Only JPG, JPEG, and PNG files are allowed for profile photo.',
        icon: 'warning'
      });
      profilePhoto.value = null;
    }
  }
};

const validateResume = (file: File | null) => {
  if (file && file.type !== 'application/pdf') {
    $q.notify({
      color: 'negative',
      message: 'Only PDF files are allowed for resume.',
      icon: 'warning'
    });
    resume.value = null;
  }
};

const handleCertificatesChange = (files: File[]) => {
  const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
  const validFiles = files.filter(file => allowedTypes.includes(file.type));
  
  if (validFiles.length !== files.length) {
    $q.notify({
      color: 'negative',
      message: 'Only PDF, JPG, JPEG, and PNG files are allowed for certificates.',
      icon: 'warning'
    });
  }

  certificates.value = validFiles;
};

const removeCertificate = (index: number) => {
  certificates.value.splice(index, 1);
};

const getFilePreview = (file: File) => {
  // Eğer dosya bir resim ise, bir önizleme URL'si oluştur
  if (file.type.startsWith('image/')) {
    return URL.createObjectURL(file);
  }
  // Resim değilse, yerine bir simge döndür (örneğin PDF için)
  return '/img/icons/pdf-file.svg';
};

const formatSize = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return Number((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};
const register = async () => {
  if (!isFormValid.value) {
    $q.notify({
      color: 'negative',
      message: 'Please fill in all required fields and ensure all uploaded files are in the correct format.',
      icon: 'warning'
    });
    return;
  }
  $q.loading.show({
      message: 'Submitting your application...',
    });

  try {
    const formData = new FormData();
    formData.append('username', email.value?.split('@')[0] || '');
    formData.append('name', name.value?.split(' ')[0] || '');
    formData.append('surname', name.value?.split(' ').slice(1).join(' ') || '');
    formData.append('email', email.value || '');
    formData.append('password', password.value || '');
    formData.append('experience', String(experience.value));
    formData.append('languages', JSON.stringify(
      selectedLanguages.value.map(lang => ({
        displayName: lang.label || '',
        code: lang.code || ''
      }))
    ));
    formData.append('timezone', timezone.value);
    formData.append('consultantEducation', JSON.stringify(consultantEducation.value));

    const primaryLanguageData = JSON.stringify({
      displayName: language.value?.label || '',
      code: language.value?.code || ''
    });
    formData.append('primaryLanguage', primaryLanguageData);

    const phoneData = JSON.stringify(phoneNumberDetails.value);
    formData.append('phone', phoneData);

    formData.append('role', 'consultant');

    const professionData = JSON.stringify({
      name: specialization.value || '',
      experts: []
    });
    formData.append('profession', professionData);

    if (resume.value) {
      formData.append('resume', resume.value);
    }

    if (profilePhoto.value) {
      formData.append('avatar', profilePhoto.value);
    }

    // Sertifikaları ekleme
    if (certificates.value.length > 0) {
      certificates.value.forEach((file, index) => {
        formData.append(`certificates`, file);
      });
    }

    console.log('Sending form data:');
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    const response = await userStore.register(formData);
    console.log("Registration response:", response)
    if (response && response.user) {
      console.log("Registration successful");
      userId.value = response.user._id;
      setTimeout(() => {
        showVerificationDialog.value = true;
        console.log("showVerificationDialog set to true");
      }, 100);
    } else {
      throw new Error('User data not received from registration');
    }
  } catch (error: unknown) {
    console.error("Registration error:", error);
    $q.notify({
      color: 'negative',
      message: error instanceof Error ? error.message : 'Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.',
      icon: 'error'
    });
  } finally {
    isLoading.value = false;
    $q.loading.hide()
  }
};
const handleVerificationComplete = (type: 'email' | 'phone') => {
  if (type === 'email') {
    isEmailVerified.value = true;
    $q.notify({
      type: 'positive',
      message: 'Email verified successfully'
    });
  } else if (type === 'phone') {
    isPhoneVerified.value = true;
    $q.notify({
      type: 'positive',
      message: 'Phone number verified successfully'
    });
  }

  // Her iki doğrulama da tamamlandıysa
  if (isEmailVerified.value && isPhoneVerified.value) {
    showVerificationDialog.value = false; // Dialog'u kapat
    $q.notify({
      type: 'positive',
      message: 'Verification completed successfully'
    });
    
    // Kullanıcıyı ApplicationSubmitted sayfasına yönlendir
    router.push({ 
      name: 'ApplicationSubmitted', 
      params: { email: email.value }
    });
  } else {
    // Kullanıcıya kalan doğrulama hakkında bilgi ver
    const remainingVerification = !isEmailVerified.value ? 'email' : 'phone number';
    $q.notify({
      type: 'info',
      message: `Please complete the ${remainingVerification} verification as well.`
    });
  }
};

</script>

<style scoped>

.scroll-y {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E1 #F1F5F9;
}

.scroll-y::-webkit-scrollbar {
  width: 8px;
}

.scroll-y::-webkit-scrollbar-track {
  background: #F1F5F9;
}

.scroll-y::-webkit-scrollbar-thumb {
  background-color: #CBD5E1;
  border-radius: 4px;
  border: 2px solid #F1F5F9;
}
.certificate-list {
  max-height: 200px; /* Listedeki öğelerin kaplayabileceği maksimum alan */
  overflow-y: auto; /* Dikey kaydırmayı etkinleştir */
  scrollbar-width: thin;
  scrollbar-color: #CBD5E1 #F1F5F9;
}

.certificate-list::-webkit-scrollbar {
  width: 8px;
}

.certificate-list::-webkit-scrollbar-track {
  background: #F1F5F9;
}

.certificate-list::-webkit-scrollbar-thumb {
  background-color: #CBD5E1;
  border-radius: 4px;
  border: 2px solid #F1F5F9;
}
.form-field {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding-left: 16px;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #94a3b8;
  margin-top: 8px;
}

.q-card.form-card {
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  background-color: #ffffff;
}

.q-card-section {
  padding: 0;
}

.q-mb-md {
  margin-bottom: 16px;
}

.q-mt-sm {
  margin-top: 8px;
}

.error-message {
  margin-top: 16px;
}
</style>