// src/components/SubscribeNewsletter.vue
<template>
  <div class="q-mb-xl">
    <div
      class="column subscribe-col"
      style="width: 100%; height: 556px; position: relative"
    >
      <div class="col-md-4 col-sm-1"></div>
      <div
        class="col-md-8 col-sm-11 row q-px-xs-lg q-px-sm-lg"
        style="
          background-color: #0069ff;
          z-index: -1;
          background-image: url('img/SubsPattern.png');
          background-repeat: no-repeat;
          border-radius: 16px;
          background-size: contain;
        "
      >
        <div
          class="offset-md-6 col-md-6 col-sm-12 column"
          style="padding-top: 80px; padding-bottom: 80px; padding-right: 7%"
        >
          <span
            style="
              font-size: 32px;
              font-weight: 700;
              line-height: 40px;
              text-align: start;
              color: #ffffff;
            "
          >
            Subscribe Our Newsletter
          </span>
          <span
            style="
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              text-align: start;
              margin-top: 8px;
              margin-bottom: 40px;
              color: #e2e8f0;
            "
          >
            Minimum Stress free weekly newsletter, features original essays by
            our teachers on happiness, meditation, and the mindful life and
            upcoming events. Enter your email to subscribe – your address will
            not be shared with anyone else.
          </span>
          <div class="row q-col-gutter-md">
            <div class="col-md-8">
              <q-input
                color="lime-11"
                filled
                v-model="text"
                label="Email"
                style="
                  background-color: #ffffff;
                  width: 100%;
                  border-radius: 16px;
                "
              >
                <template v-slot:prepend>
                  <q-img
                    src="/img/icons/mail.svg"
                    alt=""
                    style="width: 24px; height: 24px"
                  />
                </template>
              </q-input>
            </div>
            <div class="col-md-4">
              <q-btn
                icon-right="img:img/icons/plusWhite.svg"
                label="Join"
                style="
                  background-color: #1e293b;
                  color: #ffffff;
                  border-radius: 16px;
                  height: 100%;
                  width: 100%;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" style="position: relative; background-color: red;">
            <div class="col-md-5" style="position: absolute;">
                <q-img src="img/YogaNewsletter.png" alt="" />
            </div>
            <div class="col-md-7"
                style="display: flex; flex-direction: column; padding-right: 7%; padding-top: 80px; padding-bottom: 80px; ">
                <span style="font-family: 'Red Hat Display';
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 40px;
                            text-align: start;
                            color:#FFFFFF">
                    Subscribe Our Newsletter
                </span>
                <span style="font-family: 'Red Hat Display';
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            text-align: start;
                            margin-top: 8px;
                            margin-bottom: 40px;
                            color:#E2E8F0">
                    Minimum Stress free weekly newsletter, features original essays by our teachers on happiness,
                    meditation, and the mindful life and upcoming events. Enter your email to subscribe – your
                    address will
                    not be shared with anyone else.
                </span>
                <div style="display: flex; justify-content: space-between;">
                    <q-input color="lime-11" filled v-model="text" label="Email"
                        style="background-color: #FFFFFF; width: 350px; border-radius: 16px;">
                        <template v-slot:prepend>
                            <q-img src="/img/icons/mail.svg" alt="" style="width: 24px; height: 24px;" />
                        </template>
                    </q-input>
                    <q-btn icon-right="img:img/icons/plusWhite.svg" label="Join"
                        style="background-color: #1E293B; color: #FFFFFF; border-radius: 16px; padding: 8px 20px;" />
                </div>
            </div>
        </div> -->
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const text = ref("");
</script>

<style>
@media screen and (min-width: 1024px) {
  .subscribe-col {
    background-image: url("../../public/img/YogaNewsletter.png");
    background-repeat: no-repeat;
  }
}
</style>
