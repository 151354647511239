<!-- // // src/components/PopularConsultantSection.vue  -->
<template>
  <div>
    <q-tabs
      v-model="tab"
      dense
      content-class="tab-text"
      active-class="tab-active"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab
        class="text-capitalize text-subtitle1"
        name="all-categories"
        label="All Categories"
      />
      <q-tab
        class="text-capitalize text-subtitle1"
        name="psychologist"
        label="Psychologist"
      />
      <q-tab
        class="text-capitalize text-subtitle1"
        name="nutritionist"
        label="Nutritionist"
      />
      <q-tab class="text-capitalize text-subtitle1" name="yoga" label="Yoga" />
      <q-tab
        class="text-capitalize text-subtitle1"
        name="meditation"
        label="Meditation"
      />
      <q-tab
        class="text-capitalize text-subtitle1"
        name="social-life-coach"
        label="Social Life Coach"
      />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated class="consultant-tab-panels">
      <q-tab-panel name="all-categories">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>

      <q-tab-panel name="psychologist">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>

      <q-tab-panel name="nutritionist">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>
      <q-tab-panel name="yoga">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>
      <q-tab-panel name="meditation">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>
      <q-tab-panel name="social-life-coach">
        <div class="row q-col-gutter-md">
          <ConsultantCard v-for="n in 3" price="23.90"></ConsultantCard>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
  <div class="row justify-center q-mt-md">
    <q-btn
      style="
        border-radius: 10px !important;
        background-color: #0069ff !important;
      "
      icon="img:img/icons/plusWhite.svg"
      rounded
      color="primary"
      label="View All Consultants"
      class="text-capitalize q-pa-md"
    />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ConsultantCard from "./ConsultantCard.vue";

const tab = ref("all-categories");
</script>

<style>
.tab-text {
  color: #64748b;
}

.tab-active .q-tab__label {
  color: #0f172a !important;
  text-align: center !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150%; /* 24px */
}

.tab-active .q-tab__indicator {
  height: 4px !important;
}

.consultant-tab-panels .q-tab-panel {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 2%;
}
</style>
