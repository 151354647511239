import script from "./PublicFaqItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./PublicFaqItem.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QCardSection});
