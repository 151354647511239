<!-- // src/views/Public/FaqView.vue -->
<template>
  <div
    class="q-mb-xl"
    style="
      padding-top: 192px;
      background-color: #1e293b;
      background-image: url('/img/HeaderPattern.svg');
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 70px;
    "
  >
    <span
      class="page-title-m"
      style="
        font-family: 'Red Hat Display';
        font-size: 72px;
        font-weight: 600;
        line-height: 79px;
        color: #f9f9f9;
      "
    >
      FAQ
    </span>
    <div class="q-mt-lg q-mb-xl">
      <span
        style="
          font-family: 'Red Hat Display';
          font-size: 18px;
          font-weight: 600;
          line-height: 150%;
          color: #cbd5e1;
        "
      >
        Welcome to Minimum Stress Help Center...
      </span>
      <span
        style="
          font-family: 'Red Hat Display';
          font-size: 18px;
          font-weight: 400;
          line-height: 150%;
          color: #cbd5e1;
        "
      >
        We have put together some commonly asked questions
      </span>
    </div>
    <div style="width: 90%">
      <SearchInputNoCategory />
    </div>
  </div>
  <div class="container" v-for="item in testData">
    <div class="q-mb-md">
      <PublicFaqItem :title="item.title" :description="item.description" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchInputNoCategory, PublicFaqItem } from "../../components";

const testData = [
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
];
</script>
