import script from "./SubscribeNewsletter.vue?vue&type=script&lang=ts&setup=true"
export * from "./SubscribeNewsletter.vue?vue&type=script&lang=ts&setup=true"

import "./SubscribeNewsletter.vue?vue&type=style&index=0&id=066d18fb&lang=css"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QImg,QBtn});
