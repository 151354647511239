<!-- // src/components/HomePageHeader.vue  -->
<template>
  <div class="relative-position header-background" style="height: 724px">
    <div class="row">
      <div class="container">
        <div class="row" style="width: 100%">
          <div
            style="padding-top: 120px;"
            class="col-lg-7 col-md-12 col-sm-12 column justify-center col-lg-offset-5"
          >
            <span class="home-header-title">Elevate Your</span>
            <span class="home-header-title">Mind, Body, Soul</span>
            <span class="home-header-subtitle q-mt-xl">Get started</span>
            <div class="q-mt-xl">
              <SearchInput bg-color="#FFFFFF"></SearchInput>
            </div>
            <span
              class="q-mt-lg"
              style="
                font-size: 16px;
                font-weight: 400;
                line-height: 2px;
                letter-spacing: 0px;
                text-align: left;
                color: #e0e0e0;
                margin-bottom: 2%;
              "
              >Popular: Yoga, Nutritionist, Meditation</span
            >
          </div>
          <div class="col-lg-5 absolute gt-md" style="right: 0; height: 100%">
            <div class="full-height relative-position">
              <swiper
                :pagination="{
                  dynamicBullets: true,
                }"
                :modules="modules"
                :loop="true"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                class="homeSwiper"
              >
                <swiper-slide v-for="image in activeImages" :key="image?._id">
                  <img :src="image?.url" style="height: 100%" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/utils';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import SearchInput from "./SearchInput.vue";
import { useUserStore } from '@/store';

const userStore = useUserStore();
const modules = [Pagination, Autoplay];
const activeImages = ref<Array<{ _id: string; url: string }>>([]);
console.log(userStore.$state);

const fetchSliderImages = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/slider-image`);
    activeImages.value = response.data.filter((image: { isActive: boolean }) => image.isActive);
  } catch (error) {
    console.error('Error fetching slider images:', error);
  }
};

onMounted(() => {
  fetchSliderImages();
});
</script>

<style>
.header-background {
  background-color: #1e293b;
  background-image: url("../../public/img/HomePageHeaderPattern.png");
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  .header-background.q-pa-md-lg {
    padding: 0px 64px 0px 124px !important;
  }
}

.homeSwiper {
  width: 100%;
  height: 100%;
}

.homeSwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.homeSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.homeSwiper .swiper-pagination-bullet {
  background-color: #e6f0ff !important;
  opacity: 75%;
}

.homeSwiper .swiper-pagination-bullet-active {
  opacity: 100% !important;
}

.home-header-title {
  font-size: 72px;
  font-weight: 600;
  line-height: 79px;
  letter-spacing: 0em;
  text-align: left;
  color: #f9f9f9;
}

.home-header-subtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff6155;
}
</style>
