<!-- // src/components/HomeFaqSection.vue  -->
<template>
  <div class="row faq-section">
    <div class="container q-my-auto">
      <div class="row">
        <PublicColoredLineHeader text="FAQ" color="#FF6155" />
      </div>
      <div class="row q-mt-sm">
        <div class="col-md-6">
          <div class="text-h3 text-weight-bold text-left">
            <span class="text-white">Welcome to Minimum Stress </span>
            <span style="color: #ff6155">Help Center</span>
          </div>
        </div>
        <div class="col-md-6 column justify-end">
          <div class="text-body1 text-left text-white">
            We have put together some commonly asked questions
          </div>
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mt-xl">
        <div
          v-for="({ title, description,link }, index) in array"
          class="col-sm-12 col-md-6"
        >
          <PublicFaqItem :title="title" :description="description" :link="link" />
        </div>
      </div>
      <!-- <PublicFaqItem title="Are conversations recorded on Minimum stress?" description="Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed"/> -->
    </div>
  </div>
</template>
<script setup lang="ts">
import { linker } from "vue-gtag";
import PublicColoredLineHeader from "./PublicColoredLineHeader.vue";
import PublicFaqItem from "./PublicFaqItem.vue";
const array = [
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Is online therapy effective?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "How is the privacy of clients protected?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "Are conversations recorded on Minimum stress?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
  {
    title: "How can I apply for a job at Minimum stress?",
    link:"/consultant-application",
    description:
      "You can apply for a job at Minimum stress by visiting application page and submitting an application. We are always looking for talented individuals to join our team and help us achieve our mission of providing quality mental health care to everyone who needs it.",
  },
  {
    title: "How is the privacy of clients protected?",
    description:
      "Research suggests that online therapy can be just as effective as traditional in-person therapy and the American Psychological Association's 2021 Practitioner Survey found that a majority of the psychologists surveyed agreed",
  },
];
</script>
<style>
.faq-section {
  background-color: #1e293b;
  background-image: url("../../public/img/icons/Patern.png");
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 120px;
}
</style>
