<!-- // src/views/Public/LoginView.vue -->
<template>
  <div class="row">
    <div class="col-md-6 gt-sm">
      <AuthLeftSwiper imageUrl="/img/KitchenCooking.png" />
    </div>
    <div
      class="col-md-6 col-sm-12 col-xs-12 q-pa-xl"
      style="
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <span
        style="
          font-family: 'Red Hat Display';
          font-size: 24px;
          font-weight: 700;
          line-height: 150%;
          text-align: start;
          color: #1e293b;
        "
      >
        Sign In to your Account
      </span>
      <span
        class="q-mt-sm"
        style="
          font-family: 'Red Hat Display';
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          text-align: start;
          color: #64748b;
        "
      >
        Welcome back! please enter your detail
      </span>
      <q-input
        class="q-mt-xl"
        v-model="email"
        placeholder="Email"
        borderless
        style="
          border: 1px solid #e2e8f0;
          border-radius: 16px;
          padding-left: 16px;
          font-family: 'Red Hat Display';
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          color: #94a3b8;
        "
      >
        <template v-slot:prepend>
          <q-img
            src="/img/icons/mailLight.svg"
            alt=""
            style="width: 24px; height: 24px"
          />
        </template>
      </q-input>

      <q-input
        v-model="password"
        placeholder="Password"
        type="password"
        borderless
        :style="{
          border:'1px solid #e2e8f0',
          borderRadius: '16px',
          paddingLeft: '16px',
          fontFamily: 'Red Hat Display',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '150%',
          color: '#94a3b8',
          marginTop:userStore.$state.errorMessage == null ? '32px' : '16px' 
        }
        "
      >
        <template v-slot:prepend>
          <q-img
            src="/img/icons/lock.svg"
            alt=""
            style="width: 24px; height: 24px"
          />
        </template>
      </q-input>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <q-checkbox
          class="q-mt-lg"
          v-model="rememberMe"
          size="lg"
          label="Remember me"
          style="
            font-family: 'Red Hat Display';
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
            text-align: start;
            color: #94a3b8;
          "
        />
        <router-link
          to="/forgot-password"
          style="
            font-family: 'Red Hat Display';
            font-size: 14px;
            font-weight: 700;
            line-height: 150%;
            text-align: end;
            cursor: pointer;
            margin-top: 30px;
            color: #0069ff;
            text-decoration: none;
          "
        >
          Forgot Password?
        </router-link>
      </div>
      <q-btn
        label="Sign In"
        style="
          background-color: #0069ff;
          color: #ffffff;
          font-family: Red Hat Display;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          text-transform: none;
          padding-top: 16px;
          padding-bottom: 16px;
          border-radius: 16px;
          margin-top: 32px;
        "
        @click="login"
        :disable="
          email.length == 0 ||
          email.includes('@') == false ||
          password.length == 0
            ? true
            : false
        "
      />
      <span
        class="q-mt-lg"
        style="
          font-family: 'Red Hat Display';
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          text-align: center;
          color: #64748b;
        "
      >
        Don't have an account?
        <router-link to="/register" style="color: #0069ff; font-weight: 700; text-decoration: none;">Register</router-link>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, onMounted } from "vue";
import { AuthLeftSwiper } from "@/components";
import { useUserStore } from "@/store/user";
import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const $q = useQuasar();

const email = ref<string>("");
const password = ref<string>("");
const rememberMe = ref<boolean>(false);

onMounted(() => {
  userStore.removeErrorMessage();
});

watchEffect(() => {
  if (userStore.$state.isAuthenticated && userStore.$state.profile?.role) {
    const redirectPath = route.query.redirect as string || '/';
    router.push(redirectPath);
  }
});

const login = async () => {
  $q.loading.show();
  try {
    await userStore.login(email.value, password.value);
    if (userStore.$state.isAuthenticated) {
      $q.notify({
        type: 'positive',
        message: 'Login successful!',
        position: 'top'
      });
      const redirectPath = route.query.redirect as string || '/';
      router.push(redirectPath);
    } else {
      $q.notify({
        type: 'negative',
        message: userStore.$state.errorMessage || 'Login failed. Please try again.',
        position: 'top-right'
      });
    }
  } catch (error) {
    console.error('Login error:', error);
    $q.notify({
      type: 'negative',
      message: 'An error occurred during login. Please try again.',
      position: 'top-right'
    });
  } finally {
    $q.loading.hide();
  }
};
</script>

<style></style>
