<!-- // src/components/ConsultantDetailEvents.vue -->
<template>
    <div class="event-card">
      <q-card class="card">
        <q-card-section>
          <div class="row">
            <div class="col-12">
              <div class="text-title text-weight-bold" style="color: #0f172a;">
                Get Rid of Depression
              </div>
            </div>
            <div class="col-12 q-mt-sm">
              <div class="row items-center q-gutter-sm">
                <q-icon name="event" />
                <span>4 sessions</span>
                <q-icon name="schedule" />
                <span>3 hours</span>
              </div>
            </div>
            <div class="col-12 q-mt-sm">
              <div class="text-body2" style="color: #334155;">
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
              </div>
            </div>
            <div class="col-12 q-mt-sm">
              <div class="text-body2" style="color: #0069ff;">
                <a href="#">Read More ></a>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="row">
            <div class="col-6">
              <div class="text-subtitle2" style="color: #94a3b8; text-decoration: line-through;">
                $69.90
              </div>
              <div class="text-title text-weight-bold" style="color: #0069ff;">
                $49.90
              </div>
            </div>
            <div class="col-6">
              <q-btn
                class="full-width text-capitalize text-weight-bold buy-btn"
                label="Buy"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </template>
  
  <script setup lang="ts">
  </script>
  
  <style scoped>
  .event-card {
    width: 100%;
    max-width: 320px;
    margin: 10px;
  }
  
  .card {
    border-radius: 16px;
    border: 1px solid #e2e8f0;
    background-color: #f8fafc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .text-title {
    font-size: 18px;
  }
  
  .text-body2 {
    font-size: 14px;
  }
  
  .text-subtitle2 {
    font-size: 16px;
  }
  
  .q-icon {
    color: #0f172a;
  }
  
  .buy-btn {
    border-radius: 10px;
    background-color: #0069ff;
    color: white;
  }
  </style>
  