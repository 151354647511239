<!-- // src/components/ConsultantSessions.vue  -->
<template>
  <div style="height: 300px">
    <swiper
      :pagination="{
        dynamicBullets: true,
      }"
      :modules="[Autoplay, Pagination]"
      :loop="true"
      :slidesPerView="slidesPerView"
      :centeredSlides="true"
      :spaceBetween="spaceBetween"
      :grabCursor="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      class="feedback-slider"
    >
      <swiper-slide v-for="n in 8" :key="n">
        <PublicPatientFeedback
          name="Janis Joplin"
          sub-name="Teacher"
          image-url="/img/Test_PP.png"
          feedback="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import PublicPatientFeedback from "./PublicPatientFeedback.vue";
import "swiper/css";
import "swiper/css/pagination";
import { ref, onMounted } from 'vue';

const slidesPerView = ref(4);
const spaceBetween = ref(20);

const updateSliderSettings = () => {
  if (window.innerWidth <= 768) {
    slidesPerView.value = 1;
    spaceBetween.value = 10;
  } else {
    slidesPerView.value = 4;
    spaceBetween.value = 20;
  }
};

onMounted(() => {
  updateSliderSettings();
  window.addEventListener('resize', updateSliderSettings);
});
</script>

<style>
.feedback-slider {
  width: 100%;
  height: 100%;
}

.feedback-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
