<!-- // src/components/PublicFooter.vue  -->
<template>
  <footer class="public-footer">
    <div class="footer-top">
      <div class="column justify-center">
        <h2 class="text-white text-weight-bold">Talk with Us</h2>
        <h6 class="text-white text-weight-regular q-mt-none">
          We can help. Our team experts is on hand to answer your question.
        </h6>
      </div>
      <div
        class="row q-col-gutter-lg footer-form"
        style="padding-left: 135px; padding-right: 135px"
      >
        <div class="col-md-6 footer-form-item row">
          <div class="col-md-12 footer-form-item-input">
            <q-input
              filled
              v-model="text"
              label="Your Name"
              style="
                background-color: #ffffff;
                width: 100%;
                border-radius: 16px;
              "
            >
              <template v-slot:prepend>
                <q-img
                  src="/img/icons/user.svg"
                  alt=""
                  style="width: 24px; height: 24px"
                />
              </template>
            </q-input>
          </div>
          <div class="col-md-12 footer-form-item-input column justify-end">
            <q-input
              filled
              v-model="text"
              label="Email"
              style="
                background-color: #ffffff;
                width: 100%;
                border-radius: 16px;
              "
            >
              <template v-slot:prepend>
                <q-img
                  src="/img/icons/mail.svg"
                  alt=""
                  style="width: 24px; height: 24px"
                />
              </template>
            </q-input>
          </div>
        </div>
        <div class="col-md-6 footer-form-item">
          <q-input
            v-model="text"
            :input-style="{ resize: 'none' }"
            label="Your Message"
            :dense="false"
            borderless
            type="textarea"
            style="
              background-color: #ffffff;
              width: 100%;
              border-radius: 12px;
              padding-left: 10px;
              padding-right: 10px;
            "
          />
        </div>
      </div>
      <div
        class="row q-mt-md q-col-gutter-lg"
        style="padding-left: 135px; padding-right: 135px"
      >
        <div class="offset-md-6 col-md-6">
          <q-btn
            style="
              width: 100%;
              padding: 12px !important;
              border-radius: 16px !important;
              background-color: #34d399 !important;
              font-size: 14px !important;
            "
            color="primary"
            label="Send Message"
            class="text-capitalize text-weight-regular"
          />
        </div>
      </div>
    </div>
    <div class="footer-bottom q-mt-xl">
      <div class="container q-pt-xl">
        <div class="row q-col-gutter-xl">
          <div class="col-md-3 column">
            <q-img src="/img/MSLogo.svg" alt="ms-logo-footer" />
            <p
              class="text-subtitle1 text-white text-left text-weight-medium q-mt-lg"
            >
              Minimum Stress is an online platform that provides counselors in
              the holistic health profession.
            </p>
            <p
              class="text-subtitle1 text-white text-left text-weight-medium q-mt-lg"
            >
              2810 N Church St PMB 943334 Wilmington, Delaware 19802
            </p>
            <p
              class="text-subtitle1 text-white text-left text-weight-medium q-mt-lg"
            >
              support@minimumstress.com
            </p>
          </div>
          <div class="col-md-3 text-left q-mt-md-md">
            <p class="footer-bottom-title q-mb-none">Pages</p>
            <q-item class="q-pl-none q-mt-xl" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Home</q-item-section
              >
            </q-item>

            <q-item class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Services</q-item-section
              >
            </q-item>

            <q-item :focused="false" class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Blogs</q-item-section
              >
            </q-item>
          </div>
          <div class="col-md-3 text-left q-mt-md-md">
            <p class="footer-bottom-title q-mb-none">Company</p>
            <q-item class="q-pl-none q-mt-xl" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Home</q-item-section
              >
            </q-item>

            <q-item class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Services</q-item-section
              >
            </q-item>

            <q-item :focused="false" class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Blogs</q-item-section
              >
            </q-item>
          </div>
          <div class="col-md-3 text-left q-mt-md-md">
            <p class="footer-bottom-title q-mb-none">Community</p>
            <q-item class="q-pl-none q-mt-xl" to="/consultant-application" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Become part Of Minimumstress</q-item-section
              >
            </q-item>

            <q-item class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Services</q-item-section
              >
            </q-item>

            <q-item :focused="false" class="q-pl-none" to="/" dense>
              <q-item-section
                class="text-subtitle1 text-white text-left text-weight-medium"
                >Blogs</q-item-section
              >
            </q-item>
          </div>
        </div>
      </div>
      <q-separator color="white" class="q-mt-lg" />
      <div class="container">
        <div class="row q-mt-lg">
          <div class="col-md-6">
            <div class="text-body2 text-white text-left">
              ©2023 Minimum Stress. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { ref } from "vue";

const text = ref("");
</script>
<style>
.public-footer {
  background-color: #1e293b;
  background-image: url("../../public/img/FooterPatern.svg");
  background-repeat: no-repeat;
  height: 1106px;
}

.footer-top {
  padding-left: 244px;
  padding-right: 244px;
  padding-top: 50px;
}

.footer-bottom-title {
  color: #fff;
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 22.5px */
}
</style>
