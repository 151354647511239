import script from "./PopularConsultantSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./PopularConsultantSection.vue?vue&type=script&setup=true&lang=ts"

import "./PopularConsultantSection.vue?vue&type=style&index=0&id=f7e144e2&lang=css"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QBtn});
